import * as Yup from "yup";
import { size } from "lodash-es";
import { parsePhoneNumber } from "libphonenumber-js";

export const MandateCreationValidationSchema = Yup.object().shape({
  businessFunction: Yup.object()
    .required("Please select a function")
    .nullable(),
  businessFunctionRole: Yup.object()
    .required("Please select a role")
    .nullable(),
  thirdParty: Yup.object()
    .required("Please select a third party")
    .nullable(),
  contacts: Yup.array()
    .min(1, "Please select at least 1 contact")
    .required(),
  inheritance: Yup.boolean()
    .nullable()
    .optional(),
  delegationId: Yup.string()
    .nullable()
    .optional(),
  startDate: Yup.date()
    .nullable()
    .optional(),
  endDate: Yup.date()
    .nullable()
    .optional()
    .min(Yup.ref("startDate"), "End date can't be before start date"),
  phones: Yup.array().of(
    Yup.object().shape({
      number: Yup.string()
        .required("Phone number is mandatory")
        .test({
          name: "check_phone",
          exclusive: false,
          message: "Wrong phone format for current country",
          test: (value, context) => {
            const countryIso2 = (context as any)?.parent?.iso2;
            if (size(value) < 2 || !countryIso2) {
              return false;
            }

            const parsed = parsePhoneNumber(value as string, countryIso2);
            return parsed?.isValid() && parsed.country === countryIso2;
          },
        }),
    })
  ),
});
