import * as React from "react";
import { partial, map, noop } from "lodash-es";
import { IconUploadedFile } from "./IconUploadedFile";

export type AttachedFile = {
  id?: string;
  readonly name: string;
  readonly size: number;
  readonly lastModified?: number;
  readonly type?: string;
};

interface UploadFile {
  disabled?: boolean;
  onDelete: (file: AttachedFile) => void;
  onFileClick?: (file: AttachedFile) => void;
  size?: "normal" | "small";
}

export interface UploadedFilesProps extends UploadFile {
  files: AttachedFile[];
}

export interface UploadedFileProps extends UploadFile {
  file: AttachedFile;
  index: number;
}

export const bytesToSize = (bytes: number) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

  if (bytes === 0) {
    return "0 Byte";
  }
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${Math.round(((bytes / 1024 ** i) * 100) / 100)} ${sizes[i]}`;
};
const CloseFileButton: React.FC<Pick<UploadedFileProps, "onDelete" | "file">> = ({ onDelete, file }) => (
  <div>
    <button data-testid="buttonId" className="btn btn-lg btn-flat-light mx-4" onClick={partial(onDelete, file)}>
      <em className="icon icon-md pointer">close</em>
    </button>
  </div>
);
const FileInfo: React.FC<Pick<UploadedFileProps, "file" | "disabled" | "onFileClick">> = ({
  file,
  disabled,
  onFileClick,
}) => {
  const { name, size } = file;
  const fileNameClass = "font-weight-medium text-primary pointer";
  const fileSizeClass = `text-right ${!disabled ? "text-info" : "text-secondary"}`;
  return (
    <div className="pl-3 flex-grow-1">
      <div>
        {!disabled && <span className="font-weight-medium text-info">{name}</span>}
        {disabled && (
          <a className={fileNameClass} onClick={() => onFileClick && onFileClick(file)}>
            {name}
          </a>
        )}
        <br />
        <span className={fileSizeClass}>{bytesToSize(size)}</span>
      </div>
    </div>
  );
};

const UploadedFile: React.FC<UploadedFileProps> = ({ file, onDelete, disabled, index, onFileClick = noop, size }) => (
  <div
    key={`file_upload_${index}`}
    className={`bg-lvl2 ${size === "normal" ? "py-4" : "py-1"} d-flex align-items-center mb-2`}
  >
    {!disabled && size === "normal" ? (
      <IconUploadedFile />
    ) : (
      <em className={`px-4 text-secondary ${size === "normal" ? "xl" : "sm"}`}>insert_drive_file</em>
    )}
    <FileInfo file={file} disabled={disabled} onFileClick={onFileClick} />
    {!disabled && <CloseFileButton onDelete={onDelete} file={file} />}
  </div>
);

const UploadedFiles: React.FC<UploadedFilesProps> = ({ files, onDelete, disabled, onFileClick = noop, size }) => (
  <>
    {map(files, (file, index) => (
      <UploadedFile
        key={index}
        file={file}
        index={index}
        onDelete={onDelete}
        onFileClick={onFileClick}
        disabled={disabled}
        size={size}
      />
    ))}
  </>
);

export default UploadedFiles;
