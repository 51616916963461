import * as React from "react";

export const IconUploadedFile: React.VFC = () => (
  <svg className="text-info px-4" height="48" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.817 50">
    <g transform="translate(-878 -148)">
      <path
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2px"
        d="M0,2.515V48H39.817V16.4L23.415,0H0Z"
        transform="translate(879 149)"
      />
      <g transform="translate(887 163)">
        <path fill="none" d="M0,0H24V24H0Z" />
        <path fill="#fff" d="M9,16.17,4.83,12,3.41,13.41,9,19,21,7,19.59,5.59Z" />
      </g>
    </g>
  </svg>
);
