import { useField } from "formik";
import * as React from "react";
import { College } from "../../../api/mandate/mandate.typings";
import FormikFormGroup from "../../../common/components/Form/FormikFormGroup";
import { CommonPickerProps } from "../../../common/typings";
import { ContactId } from "../../../common/typings/contacts.typings";
import { CollegePicker } from "../../../ic-college-single-picker/CollegePicker";

interface Props extends CommonPickerProps<College | null> {
  id: string;
  bdrLegalId?: string;
  accountLevel?: string;
}

const MandatedCollegeField: React.FC<Props> = ({ id, bdrLegalId, accountLevel }) => {
  const [, { error, touched }, { setValue, setTouched }] = useField<ContactId | null>("contact");
  const handleOnChange = (college: College | null): void => {
    const value: ContactId | null = college?.id ? { id: college.id, type: "college" } : null;
    setValue(value);
    setTouched(true);
  };

  return (
    <FormikFormGroup label="Mandated college" fieldName="contact">
      <CollegePicker
        id={id}
        bdrLegalId={bdrLegalId}
        accountLevel={accountLevel}
        onChange={handleOnChange}
        size="lg"
        inError={touched && !!error}
      />
    </FormikFormGroup>
  );
};

export default MandatedCollegeField;
