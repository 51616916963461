import * as React from "react";
import { ContactId, PersonContact } from "../../../common/typings/contacts.typings";
import { useField } from "formik";
import FormikFormGroup from "../../../common/components/Form/FormikFormGroup";
import PersonContactMultiPicker from "../../../ic-person-contact-multi-picker/PersonContactMultiPicker";
import { useIntl } from "react-intl";

interface Props {
  contactIds?: string[];
  ignoreClickOutside?: boolean;
  canCreateContact?: boolean;
  showMandateCounter?: boolean;
  functionId?: string;
  roleId?: string;
  onClickOnMandates?: (item: PersonContact) => void;
  onCreateLinkClicked?: (term?: string) => void;
  onChange: (personContactId: ContactId[] | undefined) => void;
}

const MandatedContactField: React.FC<Props> = ({
  contactIds,
  ignoreClickOutside,
  onClickOnMandates,
  onCreateLinkClicked,
  onChange,
  canCreateContact,
  showMandateCounter,
  functionId,
  roleId,
}: Props) => {
  const [, { error, touched }, { setValue, setTouched }] = useField<ContactId[] | null>("contacts");
  const { formatMessage } = useIntl();

  const handleOnChange = (from: PersonContact[] | null): void => {
    setValue(from);
    setTouched(true);
    onChange(from || undefined);
  };
  return (
    <FormikFormGroup
      label={formatMessage({
        id: "global.mandatedContact",
        defaultMessage: "Mandated contact",
      })}
      fieldName="contacts"
    >
      <PersonContactMultiPicker
        selectedIds={contactIds}
        size="lg"
        placeholder={formatMessage({
          id: "global.picker.contact.placeholder",
          defaultMessage: "Search and select a contact",
        })}
        emitMode="not-empty"
        createLinkMode={canCreateContact ? "emit" : "none"}
        roleId={roleId}
        functionId={functionId}
        onChange={handleOnChange}
        onClickOnMandates={onClickOnMandates}
        onCreateLinkClicked={onCreateLinkClicked}
        inError={touched && !!error}
        dropdownZIndex={1000}
        ignoreClickOutside={ignoreClickOutside}
        showMandateCounter={showMandateCounter}
      />
    </FormikFormGroup>
  );
};

export default MandatedContactField;
