import { FetchFn } from "@sg-widgets/platform-api";
import { includes } from "lodash-es";
import * as React from "react";
import { useGetUserPermissionsQuery } from "../../api/contacts/contacts.queries";

export interface AppPermission {
  contactDb?: ContactDbPermissionType;
}

export type ContactDbPermissionType =
  | "RestrictedContactAccess"
  | "MandateAdmin"
  | "ManageTeams"
  | "ManageProvisioning"
  | "DomainNameAdmin";

type UseAuthorizationType = (permission: AppPermission) => boolean;

const hasPermission = (permissions: string[] | undefined, permission: string | undefined): boolean =>
  !permission || includes(permissions, permission);

export const useAuthorization = (fetch: FetchFn): UseAuthorizationType => {
  const { data: userPermissions } = useGetUserPermissionsQuery(fetch);
  return React.useCallback(
    (permissionsToCheck: AppPermission) => hasPermission(userPermissions, permissionsToCheck?.contactDb),
    [userPermissions]
  );
};
