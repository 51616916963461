import { FetchFn } from "@sg-widgets/react-core";
import * as React from "react";
import { findBusinessFunctionFilterById } from "../../api/mandate/mandate.mapper";
import { useField } from "formik";
import { find, orderBy, isEmpty} from "lodash-es";
import { BusinessFunctionFilter, BusinessFunctionRole } from "../../common/typings/mandate.typing";
import { useIntl } from "react-intl";
import { useGetBusinessFunctionsQuery } from "../../api/mandate/mandate.queries";

type FieldType<T> = { value: T; error?: string; touched: boolean; onChange: (value: T) => void };

type UseBusinessFunctionType = [
  BusinessFunctionFilter[],
  BusinessFunctionRole[],
  FieldType<BusinessFunctionFilter | null>,
  FieldType<BusinessFunctionRole | null>,
  boolean
];

export const useBusinessFunction = (
  fetch: FetchFn,
  roleItemId?: string,
  canRequestAccess?: boolean
): UseBusinessFunctionType => {
  const [, , { setValue: setRoleItemId }] = useField<string | null>("roleItemId");
  const [
    { value: bf },
    { error: bfError, touched: bfTouched },
    { setValue: setBf, setTouched: setBfTouched },
  ] = useField<BusinessFunctionFilter | null>("businessFunction");
  const [
    { value: role },
    { error: roleError, touched: roleTouched },
    { setValue: setRole, setTouched: setRoleTouched },
  ] = useField<BusinessFunctionRole | null>("businessFunctionRole");
  const { locale } = useIntl();

  const [shouldDisplay, setShouldDisplay] = React.useState<boolean>(true);

  const [roles, setRoles] = React.useState<BusinessFunctionRole[]>([]);

  const { data: functions = [], isFetching: isLoading } = useGetBusinessFunctionsQuery(fetch, locale, !canRequestAccess);

  React.useEffect(() => {
    if (!isLoading && !isEmpty(functions) && roleItemId) {
      const result = findBusinessFunctionFilterById(functions, roleItemId);
      if (result?.bf && result?.role) {
        setShouldDisplay(false);
        onRoleChange(result.role);
        onChangeBf(result.bf);
      }
    }
  }, [functions, isLoading, roleItemId]);


  React.useEffect(() => {
    setRoleItemId?.(role?.roleItemId ?? null);
  }, [role]);

  const onChangeBf = (from: BusinessFunctionFilter | null): void => {
    setBfTouched(true);
    setBf(from);
    setRoles(orderBy(from?.roles ?? [], "name"));

    // role does not exists in roles
    if (role?.id && !find(from?.roles, ({ id }) => id === role.id)) {
      // switch to the unique in the list
      const role = from?.roles?.length === 1 ? from?.roles[0] : null;
      setRole(role);
      return;
    }

    // auto select of the only item in the list
    if (from?.roles.length === 1) {
      setRole(from.roles[0]);
    }
  };

  const onRoleChange = (from: BusinessFunctionRole | null): void => {
    // cannot unselect if it's the unique item
    if (!from?.id && roles?.length === 1) {
      return;
    }
    setRole(from);
    setRoleTouched(true);
  };

  return [
    functions,
    roles,
    { value: bf, onChange: onChangeBf, error: bfError, touched: bfTouched },
    { value: role, onChange: onRoleChange, error: roleError, touched: roleTouched },
    shouldDisplay && !isLoading,
  ];
};
