import * as React from "react";
import { Loader, Select } from "@sgbs-ui/core";
import { useFetchDelegation } from "../useDelegation";
import { FetchFn } from "@sg-widgets/react-core";
import { ThirdId } from "../../../api/maestro/maestro.typings";
import { IdName } from "../../../common/typings";
import FormikFormGroup from "../../../common/components/Form/FormikFormGroup";
import { DelegationDTO } from "../../../api/mandate/mandate.typings";
import { useGenerateId } from "../../../common/hooks/useGenerateId";
import { useIntl } from "react-intl";

interface Props {
  delegationId?: string;
  thirdId?: ThirdId;
  inherit?: boolean;
  onDelegationChange?: (delegation: DelegationDTO) => void;
  fetch: FetchFn;
}

const DelegationField: React.FC<Props> = ({ delegationId, thirdId, inherit, onDelegationChange, fetch }: Props) => {
  const selectorId = useGenerateId();
  const [delegationFilter, setDelegationFilter] = React.useState<IdName<string> | null>(null);
  const [isLoading, errorMessage, delegations] = useFetchDelegation(fetch, {
    bdrId: thirdId?.bdrId ?? "",
    level: thirdId?.level ?? "",
    inherit,
  });
  const { formatMessage } = useIntl();

  React.useEffect(() => {
    if (delegationId && delegations?.length) {
      const delegation = delegations.find(({ id }) => id === delegationId);
      if (delegation) {
        const { id, title } = delegation;
        setDelegationFilter({ id, name: title });
      }
    } else setDelegationFilter(null);
  }, [delegationId, delegations, thirdId]);

  const handleOnSelectDelegation = (item: IdName<string> | null): void => {
    setDelegationFilter(item);
    const selectedDelegation = delegations.find(d => d.id === item?.id);
    if (selectedDelegation) {
      onDelegationChange?.(selectedDelegation);
    }
  };

  return (
    <FormikFormGroup
      label={formatMessage({
        id: "global.delegation",
        defaultMessage: "Delegation",
      })}
      fieldName="delegationId"
    >
      <Loader isVisible={isLoading} />
      {!isLoading && (
        <Select.SingleSelect
          id={selectorId}
          selectedItem={delegationFilter}
          onChange={handleOnSelectDelegation}
          idField="id"
          labelField="name"
          items={delegations.map(({ id, title }) => ({ id, name: title }))}
          placeholder={formatMessage({
            id: "delegations.picker.placeholder",
            defaultMessage: "Select a delegation",
          })}
          size="lg"
          errorMessage={errorMessage}
          noResultMessage={"No results found. Try another delegation."}
        />
      )}
    </FormikFormGroup>
  );
};

export default DelegationField;
