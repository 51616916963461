import { Team, tenMinutesInMs } from "@ic-anywhere/ic-dal";
import { FetchFn } from "@sg-widgets/platform-api";
import React from "react";
import {
  getTeamById,
  getTeamMembersById,
  getPermissionsByTeamId,
  requestAllTeams,
  getTeamSettings,
  requestAllOrganizationTeams,
} from "./teams.api";
import { TeamMember, TeamPermissions, TeamSettingsDTO } from "./teams.typings";
import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { mapToTeamSetting } from "./teams.mappers";

export const useGetTeamById = (fetch: FetchFn, id: string): [boolean, string | undefined, Team | undefined] => {
  const [team, setTeam] = React.useState<Team>();
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (id) {
      setIsLoading(true);
      getTeamById(fetch, id)
        .then(team => {
          setTeam(team);
        })
        .catch(() => setErrorMessage("Failed to fetch delegations"))
        .finally(() => setIsLoading(false));
    }
  }, [fetch, id]);

  return [isLoading, errorMessage, team];
};

export const useGetTeamPermissionsTeamById = (
  fetch: FetchFn,
  id: string | undefined
): [boolean, string | undefined, TeamPermissions | undefined] => {
  const [permissions, setPermissions] = React.useState<TeamPermissions>();
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (id) {
      setIsLoading(true);
      getPermissionsByTeamId(fetch, id)
        .then(defaultPermissions => {
          setPermissions(defaultPermissions);
        })
        .catch(() => setErrorMessage("Failed to fetch team permissions"))
        .finally(() => setIsLoading(false));
    }
  }, [fetch, id]);

  return [isLoading, errorMessage, permissions];
};

export const useGetTeamMembersById = (
  fetch: FetchFn,
  id: string
): [boolean, string | undefined, TeamMember[] | undefined] => {
  const [teamMembers, setTeam] = React.useState<TeamMember[]>();
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (id) {
      setIsLoading(true);
      getTeamMembersById(fetch, id)
        .then(team => {
          setTeam(team);
        })
        .catch(() => setErrorMessage("Failed to fetch delegations"))
        .finally(() => setIsLoading(false));
    }
  }, [fetch, id]);

  return [isLoading, errorMessage, teamMembers];
};

export const TEAMS_QUERIES_KEY = "TEAMS_QUERIES_KEY";

export const useFetchAllOrganizationTeams = (fetch: FetchFn, enabled = true) => {
  return useQuery<Team[], string>({
    queryKey: [TEAMS_QUERIES_KEY, "getOrganizationTeams"],
    queryFn: () => requestAllOrganizationTeams(fetch),
    enabled,
    retry: false,
    cacheTime: tenMinutesInMs,
  });
};

export const useFetchAllTeams = (fetch: FetchFn, enabled = true): UseQueryResult<Team[]> => {
  return useQuery<Team[], string>({
    queryKey: [TEAMS_QUERIES_KEY, "getTeams"],
    queryFn: () => requestAllTeams(fetch),
    enabled,
    retry: false,
    cacheTime: tenMinutesInMs,
  });
};

export const useGetTeamSettingsQuery = (
  fetch: FetchFn,
  teamId: string | undefined,
  enabled = true
): UseQueryResult<TeamSettingsDTO> => {
  return useQuery<TeamSettingsDTO, string>({
    queryKey: ["getTeamSettings", teamId],
    queryFn: () => getTeamSettings(fetch, teamId).then(result => mapToTeamSetting(result)),
    enabled: !!teamId && enabled,
    cacheTime: 0,
  });
};
