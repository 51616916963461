import * as React from "react";

interface Props {
  children?: React.ReactElement;
}

const UploadSquares: React.FC<Props> = ({ children }: Props) => (
  <>
    <div className="upload-squares" />
    {children}
    <div className="upload-squares" />
  </>
);

export default UploadSquares;
