import { Loader, Select } from "@sgbs-ui/core";
import * as React from "react";
import { useField } from "formik";
import { IdName } from "@ic-anywhere/ic-dal";
import { useRequestUboTypesReferentialQuery } from "../../../api/referentials/useRequestReferential";
import { FetchFn, useSgConnectFetch } from "@sg-widgets/react-core";
import { ContactCreatorScope } from "../../../common/sgConnectScopes";

type Props = {
  fieldId: string;
  errorMessage?: string | undefined;
  label: string;
};

const UBOField: React.FC<Props> = ({ fieldId, label, errorMessage }: Props) => {
  const fetch = useSgConnectFetch(ContactCreatorScope).fetch as FetchFn;
  const [isLoading, _, uboTypesReferentials] = useRequestUboTypesReferentialQuery(fetch);

  const [{ value: uboType }, { touched, error }, { setValue }] = useField<string | null>(fieldId);
  const handleChange = (selected: IdName<string> | null) => {
    setValue(selected?.id || null);
  };

  return (
    <div className="form-group col-md">
      <label htmlFor={fieldId}>{label} (optional)</label>
      <Loader isVisible={isLoading} />
      {uboTypesReferentials && (
        <Select.SingleSelect<IdName<string>>
          id={fieldId}
          items={uboTypesReferentials}
          idField="id"
          labelField="name"
          placeholder="Select an UBO type"
          size="lg"
          selectedItem={{ id: uboType ?? "", name: uboType ?? "" }}
          onChange={handleChange}
          inError={touched && !!error}
          keepOrder={false}
          showClearButton={false}
          errorMessage={errorMessage}
          noResultMessage={"No results found. Try another UBO."}
        ></Select.SingleSelect>
      )}
    </div>
  );
};

export default UBOField;
