import * as React from "react";
import { FetchFn } from "@sg-widgets/react-core";
import { ClientDto } from "../api/accounts/accounts.api.typings";
import { fetchClientByBdrLegalId } from "../api/accounts/accounts.api";


export const useFetchClientByThirdId = (
    fetch: FetchFn,
    bdrLegalId?: string,
    accountLevel?: string,
  ): { isLoading: boolean; errorMessage: string | undefined; account: ClientDto | null } => {
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [account, setAccount] = React.useState<ClientDto | null>(null);
  
    React.useEffect(() => {
      if (bdrLegalId && accountLevel) {
        setIsLoading(true);
        fetchClientByBdrLegalId(fetch, bdrLegalId, {
            properties: ["computedClientRootId"],
            levels: [accountLevel],
            withFunds: true,
          })
          .then(res => {
            setAccount(res);
          })
          .catch(() => setErrorMessage("Failed to fetch delegations"))
          .finally(() => setIsLoading(false));
      } else setAccount(null);
    }, [fetch]);
  
    return { isLoading, errorMessage, account};
  };