import { DEFAULT_CONTACT_DB_PROPERTIES } from "./contacts.api";
import {
  AddressCdbDto,
  ContactDbWidget,
  ContactNameDTO,
  ContactSuggestion,
  ContactSuggestionDTO,
  Scope,
  SearchCriteriaCdbDto,
} from "./contacts.typings";
import { ContactDto } from "./contacts.crm.typings";
import { ContactId, SavedPersonContact } from "../../common/typings/contacts.typings";
import { capitalize, compact, first, isEmpty, join, map, orderBy, toUpper } from "lodash-es";
import { Person } from "@ic-anywhere/ic-dal";

export interface SearchContactCriteria {
  term: string;
  isInternal?: boolean;
  onlyActive?: boolean;
  maxResultCount?: number;
  scope?: Scope;
  additionalFields?: string[];
}

export const mapToSearchCriteria = ({
  term,
  isInternal,
  onlyActive,
  maxResultCount,
  scope,
  additionalFields = [],
}: SearchContactCriteria): SearchCriteriaCdbDto => ({
  search: term,
  isInternal,
  onlyActive,
  fields: join([...DEFAULT_CONTACT_DB_PROPERTIES, ...additionalFields], ","),
  pageSize: maxResultCount,
  sort: "score",
  scope,
});

export const mapToCrmContact = (contact: ContactDto): ContactDbWidget => ({
  id: contact.id ?? "",
  name: `${contact.firstname} ${contact.lastname}`,
  givenName: contact.firstname,
  lastname: contact.lastname,
  hashtags: contact.hashtags,
  fullName: `${contact.firstname} ${contact.lastname}`,
  addresses: { ...(contact.addresses ?? []) } as AddressCdbDto[],
  phones: map(contact.phones, phone => ({
    value: phone.phone,
    type: phone.type,
    countryCodeIso2: phone.countryCodeIso2,
    isMainPhone: phone.type === "Business",
  })),
  emails: map(contact.emails, email => ({
    value: email.email,
    type: email.type,
    isMainEmail: email.type === "Professional",
  })),
  country: {
    iso3: contact?.mainAddress?.countryIso3,
    name: contact?.mainAddress?.country,
  },
  civility: contact?.civility?.title,
  jobTitle: contact?.civility?.jobTitle,
  jobType: contact?.civility?.type,
  title: contact?.civility?.title,
  isPepOrSpo: contact.isPeporsPo,
  employeeOf: {
    bdrCommercial: contact?.clientFull?.bdrCommercialId,
    bdrLegal: contact?.clientFull?.bdrLegalId,
    id: contact?.clientFull?.internalId?.id,
    name: contact?.clientFull?.name,
    type: contact?.clientFull?.internalId?.level,
  },
  employeeOfAccountId: contact?.clientFull?.internalId?.id,
  isActive: contact.isActive,
  visibility: contact?.visibility?.visibility,
  internalHrTeam: contact?.organizationTeam?.path,
  isInternal: contact?.scope?.isInternal,
  isIcUser: contact?.scope?.isIcUser,
  isInMyPortfolio: contact?.portfolio?.isInMyPortfolio,
  isSgGroupContact: contact?.scope?.isSgGroupContact,
  owners: contact.owners,
  type: contact?.civility?.type,
  mergedTo: contact.mergedTo,
  socialNetworks: [],
  igg: contact.igg,
  ecId: undefined,
});

export const mapToCrmContacts = (contacts: ContactDto[]): ContactDbWidget[] => map(contacts, mapToCrmContact);

export const mapToSavedPersonContact = (
  { id, type }: ContactId,
  fromGivenName?: string,
  fromName?: string,
  person?: Person,
  email?: string,
): SavedPersonContact => {
  const givenName = capitalize(fromGivenName);
  const name = toUpper(fromName);
  return {
    id,
    type,
    givenName,
    name,
    fullName: `${givenName} ${name}`,
    person,
    email
  };
};

const getAccountSuggestionIds = (suggestionDto: ContactSuggestionDTO): string[] => {
  return compact(
    map(
      orderBy(suggestionDto.accounts, ({ probability }) => probability, "desc"),
      account => account?.accountId
    )
  );
};

const getContactSuggestion = (suggestionDto: ContactSuggestionDTO): ContactNameDTO | undefined => {
  return first(orderBy(suggestionDto.names, ({ probability }) => probability, "desc"));
};

export const mapToContactSuggestion = (suggestionDto: ContactSuggestionDTO): ContactSuggestion => {
  const contact = getContactSuggestion(suggestionDto);
  const accountIds = getAccountSuggestionIds(suggestionDto);
  return {
    firstName: contact?.givenName,
    lastName: contact?.name,
    civility: contact?.gender,
    accountIds,
    isAlreadyExist: !isEmpty(suggestionDto.contact),
    domainStatus: suggestionDto.domainStatus,
  };
};
