import { Field, FieldArray, FieldArrayRenderProps, useFormikContext } from "formik";
import { find, isEmpty, size } from "lodash-es";
import * as React from "react";
import { Country, Phone } from "../../../common/typings";
import { MaxElementOfOptionalInput, phoneTypes } from "./contants";

import CountryField from "../../../common/components/Fields/CountryField";
import PhoneField from "../../../common/components/Fields/PhoneField";
import { MandateFormModel } from "../../../common/typings/mandate.typing";
import { FetchFn } from "@sg-widgets/react-core";
import { useFetchCountries } from "../usePhone";
import { Loader } from "@sgbs-ui/core";
import { useIntl } from "react-intl";

export const getDefaultPhone = (countries: Country[], employeeOfAccountIso3?: string): Phone => {
  if (isEmpty(employeeOfAccountIso3)) {
    return {
      type: "Callback",
      number: "",
      iso2: "FR",
      areaCode: "33",
    };
  }
  const defaultCountry = find(countries, c => c.iso3 === employeeOfAccountIso3);
  return {
    type: "Callback",
    number: "",
    iso2: defaultCountry?.iso2 || "FR",
    areaCode: defaultCountry?.areaCode || "33",
  };
};

type Props = {
  fetch: FetchFn;
};

const AddPhonesForm: React.FC<Props> = ({ fetch }: Props) => {
  const { isLoading, errorMessage, countries } = useFetchCountries(fetch);
  const { values, setFieldValue } = useFormikContext<MandateFormModel>();
  const { formatMessage } = useIntl();

  React.useEffect(() => {
    if (values?.phones?.length === 0) {
      setFieldValue("phones", [getDefaultPhone(countries)]);
    }
    return () => {
      setFieldValue("phones", []);
    };
  }, []);

  return (
    <div className="form-group">
      <div className="text-secondary font-weight-bold mb-1">
        {formatMessage({
          id: "global.phoneNumber",
          defaultMessage: "Phone number",
        })}{" "}
        (
        {formatMessage({
          id: "global.optional",
          defaultMessage: "optional",
        })}
        )
      </div>
      <Loader isVisible={isLoading} />
      {!isLoading && (
        <FieldArray
          name="phones"
          render={({ push, remove }: FieldArrayRenderProps) => (
            <>
              {values.phones?.map((_, index) => {
                return (
                  <div key={index}>
                    <Field as="select" name={`phones[${index}].type`} className="form-control col-3 mr-3">
                      {phoneTypes.map(({ label }) => (
                        <option key={label} value={label}>
                          {label}
                        </option>
                      ))}
                    </Field>
                    <div className="d-flex my-2">
                      <div className="col-3 mr-3 px-0">
                        <CountryField
                          fieldId={`phones[${index}].iso2`}
                          countries={countries}
                          errorMessage={errorMessage}
                        />
                      </div>
                      <div className="col">
                        <PhoneField fieldId={`phones[${index}]`} />
                      </div>
                      <i
                        className="icon icon-md text-secondary col-auto px-0"
                        style={{ cursor: "pointer" }}
                        onClick={() => remove(index)}
                      >
                        delete
                      </i>
                    </div>
                  </div>
                );
              })}
              {size(values.phones) < MaxElementOfOptionalInput.PHONE && (
                <button
                  type="button"
                  className="btn btn-lg sgbs-btn-default btn-icon-text w-100 mb-3"
                  onClick={() => push(getDefaultPhone(countries))}
                >
                  <i className="icon">add</i>
                  <span>
                    {formatMessage({
                      id: "global.newPhoneNumber",
                      defaultMessage: "New phone number",
                    })}
                  </span>
                </button>
              )}
            </>
          )}
        />
      )}
    </div>
  );
};

export default AddPhonesForm;
