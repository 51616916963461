import { FetchFn, useSgConnectFetch } from "@sg-widgets/react-core";
import { ChildrenRenderProps, Select } from "@sgbs-ui/core";
import { concat, find } from "lodash-es";
import * as React from "react";
import { College } from "../api/mandate/mandate.typings";
import { InvalidFeedback } from "../common/components/InvalidFeedback/InvalidFeedback";
import { useGenerateId } from "../common/hooks/useGenerateId";
import { CRMClientScope, SGMContactScope } from "../common/sgConnectScopes";
import { CommonPickerProps } from "../common/typings";
import { isStringEmpty } from "../utils/strings/stringUtils";
import { useCollegePicker } from "./useCollegePicker";

export interface Props extends CommonPickerProps<College | null> {
  bdrLegalId?: string;
  accountLevel?: string;
  selectedId?: string;
  withFunds?: boolean;
  sameThird?: boolean;
  onChange: (college: College | null) => void;
}

export const CollegePicker: React.FC<Props> = ({
  errorMessage,
  inError,
  bdrLegalId,
  accountLevel,
  id,
  selectedId,
  onReady,
  onChange,
  disabled,
  size,
  outline,
  withFunds = false,
  sameThird = false,
}: Props) => {
  const sgConnectFetch = useSgConnectFetch(concat(CRMClientScope, SGMContactScope)).fetch as FetchFn;
  const pickerId = useGenerateId(id);
  const [filteredColleges, setFilteredColleges] = React.useState<College[]>([]);
  const [selectedCollege, setSelectedCollege] = React.useState<College | null>(null);
  const { colleges, isLoading, fetchError } = useCollegePicker(sgConnectFetch, bdrLegalId, accountLevel, withFunds, sameThird);

  React.useEffect(() => {
    onReady?.();
  }, [onReady]);

  React.useEffect(() => {
    setSelectedCollege(find(colleges, ({ id }) => id === selectedId) ?? null);
    setFilteredColleges(colleges);
  }, [colleges, selectedId]);

  const handleOnSelect = (selected: College | null): void => {
    setSelectedCollege(selected);
    onChange(selected);
  };

  const onTermsChange = (terms: string): void => {
    if (!isStringEmpty(terms)) {
      const items = colleges?.filter(({ displayName }) =>
        displayName.toLocaleLowerCase().includes(terms.toLocaleLowerCase())
      );
      setFilteredColleges(items);
    } else if (isStringEmpty(terms)) {
      setFilteredColleges(colleges);
    }
  };

  const renderCollege = ({ displayName }: College, { withHighlight }: ChildrenRenderProps): React.ReactElement => {
    return <div>{withHighlight(displayName ?? "")}</div>;
  };

  const error = errorMessage ?? fetchError;

  return (
    <>
      <Select.AsyncSingleSelect<College>
        items={filteredColleges}
        selectedItem={selectedCollege}
        isLoading={isLoading}
        idField="id"
        id={pickerId}
        disabled={disabled}
        labelField="displayName"
        onTermChange={onTermsChange}
        onChange={handleOnSelect}
        size={size}
        iconName="person"
        placeholder={"Search and select college"}
        keepOrder={false}
        isOutline={outline}
        inError={inError}
        errorMessage={error}
        noResultMessage={"No results found. Try another group."}
      >
        {renderCollege}
      </Select.AsyncSingleSelect>
      {error && <InvalidFeedback errorMessage={error} />}
    </>
  );
};
