import * as React from "react";
import { Field, useField } from "formik";
import FormikFormGroup, { Props as FormikFormGroupProps } from "./FormikFormGroup";

export type Props = FormikFormGroupProps & {
  placeholder?: string;
  hint?: string;
};
const FormikField: React.FC<Props> = ({ placeholder = "", hint, ...props }: Props) => {
  const [, { error, touched }] = useField(props.fieldName);
  return (
    <FormikFormGroup {...props}>
      <Field
        name={props.fieldName}
        placeholder={placeholder}
        className={`form-control form-control-lg col-12 ${touched && error ? "is-invalid" : ""}`}
      />
      {hint && <span className="text-secondary text-small mt-2">{hint}</span>}
    </FormikFormGroup>
  );
};

export default FormikField;
