import * as React from "react";
import { ErrorMessage } from "formik";

export interface Props {
  label: string;
  fieldName: string;
  className?: string;
  isOptional?: boolean;
}

const FormikFormGroup: React.FC<React.PropsWithChildren<Props>> = ({
  label,
  fieldName,
  className = "",
  isOptional = false,
  children,
}: React.PropsWithChildren<Props>) => {
  return (
    <div className={`form-group ${className}`}>
      <label htmlFor={fieldName}>
        {label}
        {isOptional && " (optional)"}
      </label>
      <div>
        {children}
        <ErrorMessage name={fieldName} component="div" className="d-block invalid-feedback" />
      </div>
    </div>
  );
};

export default FormikFormGroup;
