import { ContactQualityView, tenMinutesInMs, ApiRequestConfig } from "@ic-anywhere/ic-dal";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { getContactIndicators } from "../../../../api/contacts/contacts.api";
import useContactDbRepository from "../../../../api/useContactDbRepository";

export const useGetContactIndicatorsQuery = (
  contactIds: string[],
  enabled = true
): UseQueryResult<ContactQualityView[], string> => {
  const key = ["getContactIndicators", contactIds];
  const repo = useContactDbRepository();

  return useQuery<ContactQualityView[], string>(
    key,
    ({ signal }) => getContactIndicators(repo, contactIds, {signal} as ApiRequestConfig),

    {
      enabled: !!contactIds?.length && enabled,
      cacheTime: tenMinutesInMs,
    }
  );
};
