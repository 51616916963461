import { FetchFn } from "@sg-widgets/platform-api";
import * as React from "react";
import { fetchDelegationById, fetchDelegations } from "../../api/mandate/mandate.api";
import { DelegationDTO, FetchDelegationParam } from "../../api/mandate/mandate.typings";

export const useFetchDelegation = (
  fetch: FetchFn,
  { bdrId, level, inherit }: FetchDelegationParam
): [boolean, string | undefined, DelegationDTO[]] => {
  const [delegations, setDelegations] = React.useState<DelegationDTO[]>([]);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (bdrId && level) {
      setIsLoading(true);
      fetchDelegations(fetch, {
        bdrId,
        level,
        inherit,
      })
        .then(delegations => {
          setDelegations(delegations);
        })
        .catch(() => setErrorMessage("Failed to fetch delegations"))
        .finally(() => setIsLoading(false));
    } else setDelegations([]);
  }, [fetch, bdrId, level, inherit]);

  return [isLoading, errorMessage, delegations];
};

export const useFetchDelegationById = (
  fetch: FetchFn,
  id: string | undefined
): { isLoading: boolean; errorMessage: string | undefined; delegation: DelegationDTO | null } => {
  const [delegation, setDelegation] = React.useState<DelegationDTO | null>(null);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (id) {
      setIsLoading(true);
      fetchDelegationById(fetch, id)
        .then(delegation => {
          setDelegation(delegation);
        })
        .catch(() => setErrorMessage("Failed to fetch delegations"))
        .finally(() => setIsLoading(false));
    } else setDelegation(null);
  }, [fetch]);

  return { isLoading, errorMessage, delegation };
};
