import * as React from "react";
import { useGenerateId } from "../common/hooks/useGenerateId";
import BusinessFunctionField from "./form/fields/BusinessFunctionField";
import { FetchFn, useSgConnectFetch } from "@sg-widgets/react-core";
import { CreateMandateScope } from "../common/sgConnectScopes";
import { Formik } from "formik";
import ThirdPartyField from "./form/fields/ThirdPartyField";
import { ThirdId } from "../api/maestro/maestro.typings";
import MandatedContactField from "./form/fields/MandatedContactField";
import { PersonContact } from "../common/typings/contacts.typings";
import MandateInheritanceField from "./form/fields/MandateInheritanceField";
import { Button } from "@sgbs-ui/core";
import Loading from "../common/components/Loading/Loading";
import { useSaveMandate } from "./form/useSaveMandate";
import { BusinessFunction, MandateFormModel, SavedMandate } from "../common/typings/mandate.typing";
import DateField from "./form/fields/DateField";
import { MandateCreationValidationSchema } from "./form/validationSchema";
import FormikField from "../common/components/Form/FormikField";
import { hasBusinessFunctionTypeOption } from "../api/mandate/mandate.mapper";
import MandatedCollegeField from "./form/fields/MandatedCollegeField";
import DelegationField from "./form/fields/DelegationField";
import UploadedFiles from "../common/components/FilesUpload/UploadedFiles";
import DropZone from "../common/components/FilesUpload/DropZone";
import AddPhonesForm from "./form/AddPhonesForm/AddPhonesForm";
import UBOField from "./form/fields/UBOField";
import { AccessRightsDTO } from "@ic-anywhere/ic-dal";
import DisplayTeamMembers from "../common/components/DisplayTeamMembers/DisplayTeamMembers";
import { useIntl } from "react-intl";
import BankAccountField from "./form/fields/BankAccountField";
import { MandateCreateError } from "../api/mandate/mandate.typings";
import { AccessRightsField } from "@ic-anywhere/ic-components";
import { useAuthorization } from "../common/hooks/useAuthorization";
import useContactDbRepository from "../api/useContactDbRepository";
import { today } from "@ic-anywhere/ic-utils";

interface Props {
  id?: string;
  roleItemId?: string;
  thirdId?: ThirdId;
  contactIds?: string[];
  delegationId?: string;
  ignoreClickOutside?: boolean;
  canCreateContact?: boolean;
  showMandateCounter?: boolean;
  showBankAccountPicker?: boolean;
  functionId?: string;
  roleId?: string;
  jointContactId?: string;
  canRequestAccessBf?: boolean;
  onClickOnMandates?: (item: PersonContact) => void;
  onCreateContactLinkClicked?: (term?: string) => void;
  onCreateJointContact?: (term?: string) => void;
  onCancel?: () => void;
  onError?: (errors: MandateCreateError[]) => void;
  onSaved?: (mandate: SavedMandate) => void;
  onBfRequestAccess?: (bf: BusinessFunction) => void;
}

const MandateCreator: React.FC<Props> = ({
  id,
  roleItemId,
  thirdId,
  contactIds,
  delegationId,
  ignoreClickOutside,
  canCreateContact,
  jointContactId,
  canRequestAccessBf,
  onBfRequestAccess,
  onCreateJointContact,
  ...props
}: Props) => {
  const widgetId = useGenerateId(id);
  const fetch = useSgConnectFetch(CreateMandateScope).fetch as FetchFn;
  const [onSubmit, isLoading] = useSaveMandate(fetch, props.onSaved, props.onError);
  const [selectedTeam, setSelectedTeam] = React.useState<string>();
  const { formatMessage } = useIntl();
  const repo = useContactDbRepository();
  const isAuthorized = useAuthorization(fetch);
  const isMandateAdmin = isAuthorized({contactDb: "MandateAdmin"});

  const handleOnCancel = (): void => {
    props?.onCancel?.();
  };
  const onBack = (): void => {
    setSelectedTeam(undefined);
  };

  return (
    <>
      <div id={widgetId} hidden={!!selectedTeam}>
        <Formik<MandateFormModel>
          initialValues={{
            businessFunction: null,
            businessFunctionRole: null,
            roleItemId: null,
            thirdParty: thirdId ?? null,
            contacts: [],
            jobTitle: "",
            inheritance: false,
            delegationId: delegationId ?? null,
            startDate: today().stringFormat,
            endDate: null,
            withSingleMandatedContact: true,
            document: null,
            uboType: null,
            contractId: "",
            phones: [],
            bankAccounts: null,
            accessRights: { visibility: "Public", owners: { teams: [] }, viewers: { teams: [] } },
          }}
          enableReinitialize={true}
          validationSchema={MandateCreationValidationSchema}
          onSubmit={onSubmit}
        >
          {({ setFieldValue, submitForm, values }) => {
            return (
              <>
                <div className="d-flex flex-column">
                  <MandateInheritanceField />
                  <ThirdPartyField
                    onChange={thirdId => {
                      setFieldValue("thirdParty", thirdId);
                    }}
                    thirdId={thirdId}
                    delegationId={delegationId}
                    fetch={fetch}
                  />
                  <DelegationField
                    onDelegationChange={delegation => {
                      setFieldValue("delegationId", delegation.id);
                    }}
                    thirdId={values.thirdParty ?? undefined}
                    delegationId={delegationId}
                    inherit={values.inheritance}
                    fetch={fetch}
                  />
                  <BusinessFunctionField
                    fetch={fetch}
                    roleItemId={roleItemId}
                    canRequestAccess={canRequestAccessBf}
                    onRequestAccess={onBfRequestAccess}
                  />
                  <BankAccountField
                    thirdId={values.thirdParty ?? thirdId}
                    show={
                      hasBusinessFunctionTypeOption(values.businessFunction?.options ?? [], "bankaccount") &&
                      !!props.showBankAccountPicker
                    }
                  />
                  {values.withSingleMandatedContact ? (
                    <MandatedContactField
                      onChange={contactIds => setFieldValue("contacts", contactIds)}
                      contactIds={contactIds}
                      ignoreClickOutside={ignoreClickOutside}
                      canCreateContact={canCreateContact}
                      onClickOnMandates={props.onClickOnMandates}
                      onCreateLinkClicked={props.onCreateContactLinkClicked}
                      showMandateCounter={props.showMandateCounter}
                      functionId={props.functionId}
                      roleId={props.roleId}
                    />
                  ) : (
                    <MandatedCollegeField
                      id={values.contacts?.[0]?.id ?? ""}
                      bdrLegalId={values?.thirdParty?.bdrId}
                      accountLevel={values?.thirdParty?.level}
                    />
                  )}
                  {hasBusinessFunctionTypeOption(values.businessFunction?.options ?? [], "jobtitle") && (
                    <FormikField
                      fieldName="jobTitle"
                      isOptional={true}
                      label={formatMessage({
                        id: "global.jobTitle",
                        defaultMessage: "Job title",
                      })}
                      hint={"By default contact's job title will apply if not fielded in."}
                    />
                  )}
                  {hasBusinessFunctionTypeOption(values.businessFunction?.options ?? [], "ubotype") && (
                    <div className="form-row">
                      <UBOField
                        fieldId="uboType"
                        label={formatMessage({
                          id: "mandates.uboType",
                          defaultMessage: "UBO type",
                        })}
                      />
                    </div>
                  )}
                  {hasBusinessFunctionTypeOption(values.businessFunction?.options ?? [], "contractid") && (
                    <FormikField
                      fieldName="contractId"
                      isOptional={true}
                      label={formatMessage({
                        id: "mandate.contractId",
                        defaultMessage: "Contract ID",
                      })}
                      placeholder={formatMessage({
                        id: "mandates.contractId.placeholder",
                        defaultMessage: "Type a contract ID",
                      })}
                    />
                  )}
                  <div className="form-row">
                    <DateField
                      fieldId="startDate"
                      label={formatMessage({
                        id: "global.startDate",
                        defaultMessage: "Start date",
                      })}
                      value={today().stringFormat}
                    />
                    <DateField
                      fieldId="endDate"
                      label={formatMessage({
                        id: "global.endDate",
                        defaultMessage: "End date",
                      })}
                    />
                  </div>
                  {values.businessFunction?.name === "Callback" && (
                    <>
                      <AddPhonesForm fetch={fetch} />
                      {!values.document && (
                        <DropZone onFilesAdded={(files: FileList) => setFieldValue("document", files.item(0))} />
                      )}
                      <UploadedFiles
                        size="normal"
                        files={values.document ? [values.document] : []}
                        onDelete={() => setFieldValue("document", null)}
                      />
                    </>
                  )}
                  <AccessRightsField
                    handleOnClickTeam={setSelectedTeam}
                    onChange={(accessRights: AccessRightsDTO | undefined) =>
                      setFieldValue("accessRights", accessRights)
                    }
                    repo={repo}
                    formatMessage={formatMessage}
                    isMandateAdmin={isMandateAdmin}
                  />
                  <div className="mt-3">
                    <Loading isLoading={isLoading} />
                    <div className="float-right">
                      <Button
                        btnType="flat"
                        color="secondary"
                        size="lg"
                        text={formatMessage({
                          id: "global.cancel",
                          defaultMessage: "Cancel",
                        })}
                        onClick={handleOnCancel}
                      />
                      <Button
                        size="lg"
                        text={formatMessage({
                          id: "global.confirm",
                          defaultMessage: "Confirm",
                        })}
                        disabled={isLoading}
                        className="ml-3"
                        onClick={submitForm}
                      />
                    </div>
                  </div>
                </div>
              </>
            );
          }}
        </Formik>
      </div>
      <div hidden={!selectedTeam}>
        <DisplayTeamMembers teamId={selectedTeam} onClickBack={onBack} fetch={fetch} />
      </div>
    </>
  );
};

export default MandateCreator;
