import { FetchFn } from "@sg-widgets/platform-api";
import { requestMe } from "./contacts.api";
import { UserProfileDTO, twoMinutesInMs } from "@ic-anywhere/ic-dal";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

export const useRequestMe = (
  fetch: FetchFn,
  enabled = true
): UseQueryResult<UserProfileDTO> => {
  return useQuery<UserProfileDTO, string>({
    queryKey: ["requestMe"],
    queryFn: () => requestMe(fetch),
    enabled,
    cacheTime: twoMinutesInMs,
  });
};
