import { IdName } from "@ic-anywhere/ic-dal";
import { FetchFn } from "@sg-widgets/platform-api";
import React from "react";
import { useIntl } from "react-intl";
import { jobTypeLabelsValues } from "../../ic-contact-creator/Form/contants";
import { requestJobTypesReferential, requestUboTypesReferential } from "./referentials.api";

export const useRequestJobTypesReferentialQuery = (
  fetch: FetchFn
): [boolean, string | undefined, IdName<string>[] | undefined] => {
  const [jobTypesReferential, setJobTypesReferential] = React.useState<IdName<string>[]>();
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { locale } = useIntl();

  React.useEffect(() => {
    setIsLoading(true);
    requestJobTypesReferential(fetch, locale)
      .then(r => {
        r?.sort((a, b) => a.name.localeCompare(b.name));
        setJobTypesReferential(r);
      })
      .catch(() => {
        setJobTypesReferential(jobTypeLabelsValues);
        setErrorMessage("Failed to fetch jobTypeReferential");
      })
      .finally(() => setIsLoading(false));
  }, [fetch]);

  return [isLoading, errorMessage, jobTypesReferential];
};

export const useRequestUboTypesReferentialQuery = (
  fetch: FetchFn
): [boolean, string | undefined, IdName<string>[] | undefined] => {
  const [uboTypesReferential, setUboTypesReferential] = React.useState<IdName<string>[]>();
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { locale } = useIntl();

  React.useEffect(() => {
    setIsLoading(true);
    requestUboTypesReferential(fetch, locale)
      .then(r => setUboTypesReferential(r))
      .catch(() => {
        setErrorMessage("Failed to fetch UboTypesReferential");
      })
      .finally(() => setIsLoading(false));
  }, [fetch]);

  return [isLoading, errorMessage, uboTypesReferential];
};
