import * as React from "react";
import { Switch } from "@sgbs-ui/core";
import { useField } from "formik";
import { useIntl } from "react-intl";

const MandateInheritanceField: React.VFC = () => {
  const [{ value }, , { setValue }] = useField<boolean>("inheritance");
  const { formatMessage } = useIntl();

  return (
    <div className="align-self-end mb-n4">
      <Switch
        text={formatMessage({
          id: "global.inheritance",
          defaultMessage: "Inheritance",
        })}
        checked={value}
        onChange={setValue}
      />
    </div>
  );
};

export default MandateInheritanceField;
