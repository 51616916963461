import * as React from "react";
import { widgetize, WidgetPropsMapping } from "@sg-widgets/react-core";
import WidgetTracker from "../common/components/WidgetTracker";
import { WithSGB4 } from "../common/components/WithSGB4";
import MandateCreator from "./MandateCreator";
import { ThirdId } from "../api/maestro/maestro.typings";
import { PersonContact } from "../common/typings/contacts.typings";
import { BusinessFunction, SavedMandate } from "../common/typings/mandate.typing";
import { convertToBoolOrUndefined } from "../utils/strings/stringify";
import { FLAG_ICON_CSS_FILE } from "../common/constants";
import AppIntlProvider from "../common/components/intl/AppInltProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { defaultQueryClientOptions } from "@ic-anywhere/ic-dal";
import { MandateCreateError } from "../api/mandate/mandate.typings";

const TAG = "ic-mandate-creator";

interface Props {
  id?: string;
  roleItemId?: string;
  thirdId?: ThirdId;
  contactIds?: string[];
  delegationId?: string;
  ignoreClickOutside?: string;
  canCreateContact?: string;
  showMandateCounter?: string;
  functionId?: string;
  roleId?: string;
  jointContactId?: string;
  language?: string;
  showBankAccountPicker?: string;
  canRequestAccessBf?: string;
  onClickOnMandates?: (item: PersonContact) => void;
  onCreateContactLinkClicked?: (term?: string) => void;
  onCreateJointContact?: (term?: string) => void;
  onCancel?: () => void;
  onError?: (errors: MandateCreateError[]) => void;
  onSaved?: (mandate: SavedMandate) => void;
  onBfRequestAccess?: (bf: BusinessFunction) => void;
}

const MandateCreatorWidget: React.FC<Props> = ({
  id,
  roleItemId,
  thirdId,
  contactIds,
  delegationId,
  ignoreClickOutside,
  functionId,
  roleId,
  jointContactId,
  canCreateContact = "true",
  showMandateCounter = "true",
  showBankAccountPicker = "false",
  canRequestAccessBf = "false",
  language,
  onCreateJointContact,
  onBfRequestAccess,
  ...props
}: Props) => {
  const queryClient = new QueryClient({ defaultOptions: defaultQueryClientOptions });

  return (
    <AppIntlProvider language={language}>
      <WithSGB4 extraUrls={[FLAG_ICON_CSS_FILE]}>
        <QueryClientProvider client={queryClient}>
          <MandateCreator
            id={id}
            roleItemId={roleItemId}
            thirdId={thirdId}
            contactIds={contactIds}
            delegationId={delegationId}
            ignoreClickOutside={convertToBoolOrUndefined(ignoreClickOutside)}
            canCreateContact={convertToBoolOrUndefined(canCreateContact)}
            showMandateCounter={convertToBoolOrUndefined(showMandateCounter)}
            showBankAccountPicker={convertToBoolOrUndefined(showBankAccountPicker)}
            canRequestAccessBf={convertToBoolOrUndefined(canRequestAccessBf)}
            functionId={functionId}
            roleId={roleId}
            jointContactId={jointContactId}
            onClickOnMandates={props.onClickOnMandates}
            onCreateContactLinkClicked={props.onCreateContactLinkClicked}
            onCreateJointContact={onCreateJointContact}
            onSaved={props.onSaved}
            onError={props.onError}
            onCancel={props.onCancel}
            onBfRequestAccess={onBfRequestAccess}
          />
          <WidgetTracker widgetName={TAG} />
        </QueryClientProvider>
      </WithSGB4>
    </AppIntlProvider>
  );
};

widgetize(
  TAG,
  MandateCreatorWidget,
  {
    id: WidgetPropsMapping.asString(),
    roleItemId: WidgetPropsMapping.asString(),
    thirdId: WidgetPropsMapping.asObject(),
    contactIds: WidgetPropsMapping.asObject(),
    delegationId: WidgetPropsMapping.asString(),
    ignoreClickOutside: WidgetPropsMapping.asString(),
    canCreateContact: WidgetPropsMapping.asString(),
    functionId: WidgetPropsMapping.asString(),
    roleId: WidgetPropsMapping.asString(),
    jointContactId: WidgetPropsMapping.asString(),
    language: WidgetPropsMapping.asString(),
    showMandateCounter: WidgetPropsMapping.asString(),
    showBankAccountPicker: WidgetPropsMapping.asString(),
    canRequestAccessBf: WidgetPropsMapping.asString(),
    onSaved: WidgetPropsMapping.asEventEmitter("saved"),
    onError: WidgetPropsMapping.asEventEmitter("error"),
    onCancel: WidgetPropsMapping.asEventEmitter("cancel"),
    onCreateContactLinkClicked: WidgetPropsMapping.asEventEmitter("create-contact"),
    onCreateJointContact: WidgetPropsMapping.asEventEmitter("create-contact-with-joint"),
    onClickOnMandates: WidgetPropsMapping.asEventEmitter("mandate-clicked"),
    onBfRequestAccess: WidgetPropsMapping.asEventEmitter("bf-request-access-clicked"),
  },
  { neverInjectGlobalCss: true }
);
