import { FetchFn } from "@sg-widgets/platform-api";
import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { requestUserPermissions } from "./contacts.api";
import { useRequestMe } from "./useRequestMe";

export const useGetUserPermissionsQuery = (fetch: FetchFn, enabled = true): UseQueryResult<string[]> => {
  const { data: userProfile } = useRequestMe(fetch);
  return useQuery<string[], string>({
    queryKey: ["getUserPermission", userProfile?.userId],
    queryFn: () => requestUserPermissions(fetch, userProfile?.userId),
    enabled: enabled && !!userProfile,
    cacheTime: 0,
  });
};
