import { TeamDTO, Team } from "@ic-anywhere/ic-dal";
import { TeamNode, TeamReason, TeamSettingsDTO } from "./teams.typings";
import { TeamWithReason } from "../api.typings";
import { isContainSubString } from "@ic-anywhere/ic-utils";
import { filter, each, isEmpty, orderBy, includes, get, map } from "lodash-es";

export const mapToTeams = (teams: TeamDTO[]): Team[] => map(teams, mapToTeam) ?? [];

export const mapToTeam = (team: TeamDTO): Team => ({
  id: team?.id,
  name: team?.path ?? "",
  parentId: team?.parentOrganizationTeamId,
});

export const mapToTeamWithReason = ({id, reason, name, fullName}: TeamWithReason): TeamWithReason => ({
  id,
  reason: reason ?? TeamReason.implicit,
  name: name ?? "",
  fullName: fullName ?? ""
})

export const mapToTeamsWithReason = (teams: TeamWithReason[]): TeamWithReason[] => map(teams, mapToTeamWithReason) ?? [];

export const mapToTeamSetting = ({permissions, objectAccessRights}: TeamSettingsDTO): TeamSettingsDTO => ({
  permissions,
  objectAccessRights: {
    ...objectAccessRights,
    owners: {teams: mapToTeamsWithReason(objectAccessRights.owners?.teams ?? [])},
    viewers: {teams: mapToTeamsWithReason(objectAccessRights.viewers?.teams ?? [])}
  }
});

export const mapToTeamTree = (teams: Team[]): TeamNode[] => {
  const roots = findRootNodes(teams).map((team) => mapToRootNode(team));
  const leafs = filter(teams, (team) => team.parentId !== null);
  return mapToTree(roots, leafs);
};

export const mapToTree = (roots: TeamNode[], leafs: Team[]): TeamNode[] => {
  each(roots, (parentTeam) => {
    parentTeam.children = orderBy(
      (leafs ?? []).filter((team) => team.parentId === parentTeam.id).map((node) => mapToLeafNode(node, parentTeam)),
      "name"
    );

    if (!isEmpty(parentTeam.children)) {
      mapToTree(parentTeam.children, leafs);
    }
  });
  return orderBy(roots, "name");
};

const findRootNodes = (nodes: Team[]): Team[] =>
  //  node without parent or children with parent that does not exist on the list
  nodes.filter(
    (node) =>
      node.parentId === null ||
      !includes(
        nodes.map((rootNode) => rootNode.id),
        node.parentId
      )
  );

const mapToRootNode = (team: Team): TeamNode => ({
  id: team.id ?? "",
  name: team.name || "Select all",
  path: team.fullName || team.name || "",
  expanded: true,
  selected: false,
  hidden: false,
  parent: undefined,
  sphere: !isEmpty(team.sphere) ? team.sphere : undefined,
  children: [],
});

const mapToLeafNode = (team: Team, parentTeam: TeamNode): TeamNode => ({
  id: team.id ?? "",
  name: formatTeamName(team, parentTeam),
  path: team.fullName || team.name || "",
  parent: parentTeam,
  children: [],
  expanded: false,
  selected: false,
  hidden: false,
  sphere: get(team, "sphere", undefined),
});

const formatTeamName = (team: Team, parent: TeamNode): string => {
  const parentName = parent && parent.path ? parent.path : null;
  if (parentName !== null && isContainSubString(team.name, parentName)) {
    const teamNames = team.name.split("/");
    return teamNames.length > 0 ? teamNames[teamNames.length - 1] : team.name;
  }
  return team.name;
};