import * as React from "react";
import { isStringEmpty } from "../../../utils/strings/stringUtils";

type Props = React.PropsWithChildren<{
  value?: string;
  className?: string;
  isReadOnly?: boolean;
}>;

export const BulletValue: React.FC<Props> = ({ value, className = "", isReadOnly = false, children }) => {
  if (isStringEmpty(value) && !children) {
    return null;
  }
  const textColorClass = isReadOnly ? "" : `text-secondary`;
  return (
    <>
      <span className={`${textColorClass} font-weight-light mx-1`}>&bull;</span>
      <span className={`${className} ${textColorClass}`}>{children ? children : value}</span>
    </>
  );
};
