import * as React from "react";
import { fetchColleges } from "../api/mandate/mandate.api";
import { College } from "../api/mandate/mandate.typings";
import { fetchClientByBdrLegalId, getClientById } from "../api/accounts/accounts.api";
import { FetchFn } from "@sg-widgets/react-core";

export type useCollegePickerOutput = { colleges: College[]; isLoading: boolean; fetchError: string | undefined };

export const useCollegePicker = (
  fetch: FetchFn,
  bdrLegalId?: string,
  accountLevel?: string,
  withFunds = false,
  sameThird = false
): useCollegePickerOutput => {
  const [colleges, setColleges] = React.useState<College[]>([]);
  const [fetchError, setFetchError] = React.useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isInit, setIsInit] = React.useState<boolean>(true);
  const clientErrorMsg = "Failed to fetch client";

  const handleErrorMessage = (error, msg: string): void => {
    setFetchError(error?.userMessage ?? error?.message ?? msg);
  };

  React.useEffect(() => {
    setIsInit(false);
  }, []);

  const fetchClientColleges = () => {
    if (bdrLegalId && accountLevel) {
      fetchColleges(fetch, {
        level: accountLevel,
        bdrLegalId: bdrLegalId,
        inherit: false,
      })
        .then(items => {
          setFetchError(undefined);
          setColleges(items);
        })
        .catch(error => handleErrorMessage(error, "Failed to fetch colleges"))
        .finally(() => setIsLoading(false));
    }
  };

  const fetchRootClientColleges = () => {
    if (bdrLegalId && accountLevel) {
      fetchClientByBdrLegalId(fetch, bdrLegalId, {
        properties: ["computedClientRootId"],
        levels: [accountLevel],
        withFunds,
      })
        .then(account => {
          if (account && account.computedClientRootId) {
            getClientById(fetch, account.computedClientRootId)
              .then(rootAccount => {
                if (rootAccount) {
                  fetchColleges(fetch, {
                    // WORK AROUND WHEN ROOTACCOUNT IS NOT LEGAL (JIRA ICMOBILE-21295)
                    level:
                      (rootAccount.clientIds?.bdrLegalId?.id && rootAccount.clientIds?.bdrLegalId?.level) ||
                      accountLevel ||
                      "",
                    bdrLegalId: rootAccount.clientIds?.bdrLegalId?.id || bdrLegalId || "",
                    inherit: true,
                  })
                    .then(items => {
                      setFetchError(undefined);
                      setColleges(items);
                    })
                    .catch(error => handleErrorMessage(error, "Failed to fetch colleges"));
                }
              })
              .catch(error => handleErrorMessage(error, clientErrorMsg));
          }
        })
        .catch(error => handleErrorMessage(error, clientErrorMsg))
        .finally(() => setIsLoading(false));
    }
  };

  React.useEffect(() => {
    if (sameThird) {
      fetchClientColleges();
    } else {
      fetchRootClientColleges();
    }
  }, [fetch, accountLevel, bdrLegalId, sameThird]);

  const loading = isLoading || isInit;

  return { colleges, isLoading: loading, fetchError };
};
