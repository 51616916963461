import * as React from "react";
import { generateUID } from "@sgbs-ui/core";
import { useField } from "formik";

interface Props {
  fieldId: string;
  label: string;
  value?: any;
}

const DateField: React.FC<Props> = ({ fieldId, label, value }: Props) => {
  const pickerId = generateUID();
  const ref = React.useRef<HTMLElement>(null);
  const [, { error, touched }, { setValue, setTouched }] = useField(fieldId);

  const setError = React.useCallback((state: "invalid" | null, message?: string) => {
    (ref?.current as any)?.setState?.(state, message);
  }, []);

  const onChange = React.useCallback(
    (value: any): void => {
      setValue(value.detail?.formatted);
      setTouched(true);
    },
    [setTouched, setValue]
  );

  React.useEffect(() => {
    if (ref?.current && value) {
      (ref?.current as any).selectDate?.(value ? [new Date(value).getTime()] : null);
    }
  }, [value, ref]);

  const onReset = React.useCallback((): void => {
    setValue(null);
    setTouched(true);
  }, [setTouched, setValue]);

  React.useEffect(() => {
    const dateSelected = "dateSelected";
    const dateUnSelected = "dateUnSelected";
    const inputCleared = "inputCleared";
    ref?.current?.addEventListener(dateSelected, onChange);
    ref?.current?.addEventListener(dateUnSelected, onReset);
    ref?.current?.addEventListener(inputCleared, onReset);
    return () => {
      ref?.current?.removeEventListener(dateSelected, onChange);
      ref?.current?.removeEventListener(dateUnSelected, onReset);
      ref?.current?.removeEventListener(inputCleared, onReset);
    };
  }, [onChange, onReset, ref]);

  React.useEffect(() => {
    setError(touched && !!error ? "invalid" : null, error);
  }, [setError, error, touched]);

  return (
    <div className="form-group col-md">
      <label htmlFor={fieldId}>{label} </label>
      <sg-datepicker ref={ref} input-id={pickerId} size="lg" format="yyyy-MM-dd" display-format={"dd MMM yyyy"} />
    </div>
  );
};

export default DateField;
