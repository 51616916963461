import * as React from "react";
import { map, sortBy } from "lodash-es";
import { SearchNoResult } from "@ic-anywhere/ic-components";
import { useGetTeamById, useGetTeamMembersById } from "../../../api/teams/teams.queries";
import { FetchFn } from "@sg-widgets/platform-api";
import { Button, Loader } from "@sgbs-ui/core";

export interface DisplayTeamMembersProps {
  teamId?: string;
  onClickBack: () => void;
  fetch: FetchFn;
}

const DisplayTeamMembers: React.FC<DisplayTeamMembersProps> = ({
  teamId,
  onClickBack,
  fetch,
}: DisplayTeamMembersProps) => {
  const [isLoadingTeam, , team] = useGetTeamById(fetch, teamId ?? "");
  const [isLoadingTeamMembers, , teamMembers] = useGetTeamMembersById(fetch, teamId ?? "");

  const sortedTeamMembers = sortBy(teamMembers, data => data.name);

  return (
    <>
      <Loader isVisible={isLoadingTeam} />
      {!!team && <h2 className="d-flex align-self-center modal-title text-black">{team?.name}</h2>}
      <Button
        text="Back to mandate"
        btnType="flat"
        size="xl"
        color="secondary"
        icon="arrow_back"
        iconPosition="left"
        onClick={onClickBack}
      />
      <Loader isVisible={isLoadingTeamMembers} />
      {!!teamMembers?.length ? (
        <>
          <ul className="list-group list-group-flush">
            {map(sortedTeamMembers, teamMember => (
              <li key={teamMember.id} className="list-group-item border-light border-light d-flex">
                <div className="col-4">
                  <span>{teamMember.givenName} </span>
                  <span className="font-weight-bold">{teamMember.name} </span>
                </div>
                <span className="col-4 font-weight-bold">{teamMember.internalHrTeam} </span>
              </li>
            ))}
          </ul>
        </>
      ) : !isLoadingTeamMembers && !isLoadingTeam ? (
        <SearchNoResult icon="search" title={`No members was found for ${team?.name} team`} />
      ) : (
        <></>
      )}
    </>
  );
};

export default DisplayTeamMembers;
