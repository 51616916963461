import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { fetchFunctionRoles } from "./mandate.api";
import { FetchFn } from "@sg-widgets/platform-api";
import { fiveMinutesInMs } from "@ic-anywhere/ic-dal";
import { RoleItemDTO } from "./mandate.typings";
import { mapToBusinessFunctionFilter } from "./mandate.mapper";
import { BusinessFunctionFilter } from "../../common/typings/mandate.typing";

export const useGetRoleItemsQuery = (
  fetch: FetchFn,
  locale: string,
  onlyAssignableByMe = true,
  enabled = true
): UseQueryResult<RoleItemDTO[]> => {
  return useQuery<RoleItemDTO[], string>({
    queryKey: ["getAllRoles", "onlyAssignableByMe", onlyAssignableByMe],
    queryFn: () => fetchFunctionRoles(fetch, locale, onlyAssignableByMe),
    enabled,
    cacheTime: fiveMinutesInMs,
  });
};

export const useGetBusinessFunctionsQuery = (
    fetch: FetchFn,
    locale: string,
    onlyAssignableByMe = true,
    enabled = true
  ): UseQueryResult<BusinessFunctionFilter[]> => {
    return useQuery<BusinessFunctionFilter[], string>({
      queryKey: ["getAllBusinessFunctions", "onlyAssignableByMe", onlyAssignableByMe],
      queryFn: () => fetchFunctionRoles(fetch, locale, onlyAssignableByMe).then(result => mapToBusinessFunctionFilter(result)),
      enabled,
      cacheTime: fiveMinutesInMs,
    });
  };
