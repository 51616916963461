import { Country } from "../../../common/typings";
import { CountryDto } from "../../../api/countries/countries.typings";
import { CountryCode, getCountryCallingCode } from "libphonenumber-js";
import { map } from "lodash-es";

export const mapToCountries = (countries: CountryDto[]): Country[] => {
  return map(countries, ({ name, iso2, iso3 }: CountryDto) => {
    let areaCode;
    try {
      areaCode = getCountryCallingCode(iso2 as CountryCode) as string;
    } catch {
      areaCode = "";
    }
    return { name, iso2, iso3, areaCode };
  });
};
