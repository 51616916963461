import { FetchFn } from "@sg-widgets/platform-api/src";
import { chunk, first, flatten, isEmpty, map } from "lodash-es";
import { createRepository } from "../api";
import { ContactCreatorFormModelDto, IdNames } from "../../common/typings";
import {
  ChangePositionDto,
  ContactDbDto,
  ContactSuggestionDTO,
  EmailDetails,
  PersonalEmailJustificationsDto,
  SearchContactDbProperties,
  SearchCriteriaCdbDto,
  UserPermissions,
} from "./contacts.typings";
import { stringifyLists } from "../../utils/strings/stringify";
import { CONTACT_DB_HEADERS, MAX_GUIDS_F5_SUPPORTED } from "../api.constants";
import { ContactDto } from "./contacts.crm.typings";
import { PersonContact, PersonFormModel, SavedPersonContact } from "../../common/typings/contacts.typings";
import { isStringEmpty } from "../../utils/strings/stringUtils";
import { mapToSavedPersonContact } from "./contacts.mapper";
import {
  ApiRepository,
  ApiRequestConfig,
  ContactDTO,
  ContactQualityDTO,
  ContactQualityView,
  mapFromQualityDb,
  mapToUserProfile,
  Person,
  ThirdId,
  UserProfileDTO,
} from "@ic-anywhere/ic-dal";

type CrmContactCriteria = { isInternal?: boolean; onlyActive?: boolean; pageSize?: number };
const contactDbUrl = "/1.0/sg/contacts";
const crmContactUrl = "/2.0/contacts";
const personUrl = "/1.0/sg/persons";
const mixPersonsContactsUrl = "/private/0.0/mixPersonsContacts";
const privateUserUrl = "/private/0.0/users";

export const DEFAULT_CONTACT_DB_PROPERTIES: SearchContactDbProperties[] = [
  "account",
  "jobTitle",
  "title",
  "emails",
  "country",
  "isInternal",
  "internalHrTeam",
];

const DEFAULT_CRM_PROPERTIES = [
  "clientFull",
  "civility",
  "mainEmail",
  "emails",
  "mainPhone",
  "phones",
  "mainAddress",
  "addresses",
  "visibility",
  "igg",
  "hashtags",
  "scope",
  "portfolio",
  "team",
];

const chunkFetchByIds = <T>(ids: string[], fetch: (ids: string[]) => Promise<T[]>): Promise<T[]> => {
  const allCalls = map(chunk(ids, MAX_GUIDS_F5_SUPPORTED), fetch);
  return Promise.all(allCalls).then(flatten);
};

export const getContactById = (
  fetch: FetchFn,
  id: string,
  fields = DEFAULT_CONTACT_DB_PROPERTIES,
  onlyActive = false
): Promise<ContactDbDto> => {
  return createRepository(fetch, CONTACT_DB_HEADERS).get<ContactDbDto>(
    `${contactDbUrl}/${id}`,
    stringifyLists({ fields, onlyActive })
  );
};

export const requestMe = (
  fetch: FetchFn,
  fields: SearchContactDbProperties[] = [...DEFAULT_CONTACT_DB_PROPERTIES, "organizationteam", "allowedActions"]
): Promise<UserProfileDTO & { userId: string }> =>
  createRepository(fetch, CONTACT_DB_HEADERS)
    .get<ContactDTO>(`${contactDbUrl}/_me`, stringifyLists({ fields }))
    .then(res => ({ ...mapToUserProfile(res), userId: res.id }));

export const getContactsById = async (
  fetch: FetchFn,
  ids: string[],
  fields = DEFAULT_CONTACT_DB_PROPERTIES,
  onlyActive = true
): Promise<ContactDbDto[]> => {
  const fetchByIds = (chunkedIds: string[]): Promise<ContactDbDto[]> =>
    createRepository(fetch, CONTACT_DB_HEADERS)
      .get<{ contacts: ContactDbDto[] }>(contactDbUrl, stringifyLists({ fields, ids: chunkedIds, onlyActive }))
      .then(res => res?.contacts);
  return chunkFetchByIds(ids, fetchByIds);
};

export const searchContacts = (
  repository: ApiRepository,
  criteria: SearchCriteriaCdbDto,
  requestConfig?: ApiRequestConfig
): Promise<ContactDbDto[]> =>
  repository
    .get<{ contacts: ContactDbDto[] }>(contactDbUrl, stringifyLists(criteria), requestConfig as ApiRequestConfig)
    .then(res => res?.data.contacts ?? []);

export const getIcUsersById = (
  fetch: FetchFn,
  id: string,
  { isInternal, onlyActive }: CrmContactCriteria
): Promise<ContactDto> => {
  return createRepository(fetch).get<ContactDto>(
    `${crmContactUrl}/${id}`,
    stringifyLists({ properties: DEFAULT_CRM_PROPERTIES, isIcUser: true, isInternal, onlyActive })
  );
};

export const getIcUsersByIds = (fetch: FetchFn, allIds: string[]): Promise<ContactDto[]> => {
  const fetchByIds = (ids: string[]): Promise<ContactDto[]> =>
    createRepository(fetch).get<ContactDto[]>(
      crmContactUrl,
      stringifyLists({ properties: DEFAULT_CRM_PROPERTIES, isIcUser: true, ids })
    );
  return chunkFetchByIds<ContactDto>(allIds, fetchByIds);
};

export const searchIcUsers = (
  fetch: FetchFn,
  search: string,
  { isInternal, onlyActive, pageSize = 250 }: CrmContactCriteria
): Promise<ContactDto[]> => {
  return createRepository(fetch).get<ContactDto[]>(
    crmContactUrl,
    stringifyLists({
      properties: DEFAULT_CRM_PROPERTIES,
      isIcUser: true,
      search,
      isInternal,
      onlyActive,
      pageSize,
    })
  );
};

export const searchIndividualContacts = (
  repository: ApiRepository,
  search: string,
  pageSize = 20,
  functionId?: string,
  roleId?: string,
  thirdId?: ThirdId,
  requestConfig?: ApiRequestConfig
): Promise<PersonContact[]> => {
  return repository
    .get<{ mixedSearchResults: PersonContact[] }>(
      mixPersonsContactsUrl,
      {
        search,
        pageSize,
        businessFunctionId: isStringEmpty(functionId) ? undefined : functionId,
        roleId: isStringEmpty(roleId) ? undefined : roleId,
        mandateBdrLegalId: thirdId?.bdrId,
        mandateBdrLegalLevel: thirdId?.level,
      },
      requestConfig as ApiRequestConfig
    )
    .then(response =>
      map(response.data.mixedSearchResults, contact => ({
        ...contact,
        type: contact.type.toLocaleLowerCase() as "contact" | "person",
      }))
    );
};

export const fetchIndividualContactByIds = (
  fetch: FetchFn,
  contactIds: string[],
  functionId?: string,
  roleId?: string,
  thirdId?: ThirdId
): Promise<PersonContact[]> => {
  if (!contactIds || contactIds?.length === 0) {
    return Promise.resolve([]);
  }
  const fetchByIds = (ids: string[]): Promise<PersonContact[]> =>
    createRepository(fetch, CONTACT_DB_HEADERS)
      .get<{ mixedSearchResults: PersonContact[] }>(mixPersonsContactsUrl, {
        ids,
        businessFunctionId: isStringEmpty(functionId) ? undefined : functionId,
        roleId: isStringEmpty(roleId) ? undefined : roleId,
        mandateBdrLegalId: thirdId?.bdrId,
        mandateBdrLegalLevel: thirdId?.level,
      })
      .then(response =>
        map(response.mixedSearchResults, contact => ({
          ...contact,
          type: contact.type.toLocaleLowerCase() as "contact" | "person",
        }))
      );
  return chunkFetchByIds<PersonContact>(contactIds, fetchByIds);
};

export const fetchIndividualContactById = (
  fetch: FetchFn,
  id: string,
  functionId?: string,
  roleId?: string,
  thirdId?: ThirdId
): Promise<PersonContact | null> => {
  if (isStringEmpty(id)) {
    return Promise.resolve(null);
  }
  return fetchIndividualContactByIds(fetch, [id], functionId, roleId, thirdId).then(persons =>
    persons?.length > 0 ? persons[0] : null
  );
};

export const checkEmailDetailsApi = (fetch: FetchFn, emailDetails: EmailDetails): Promise<void> =>
  createRepository(fetch, CONTACT_DB_HEADERS).post(`${contactDbUrl}/check`, emailDetails);

export const getPersonalEmailJustificationsApi = (fetch: FetchFn): Promise<IdNames<number>> =>
  createRepository(fetch, CONTACT_DB_HEADERS)
    .get<PersonalEmailJustificationsDto>(`${contactDbUrl}/personalemailjustifications`)
    .then(result => result?.justifications);

export const createContactApi = (
  fetch: FetchFn,
  contact: ContactCreatorFormModelDto,
  etag?: string
): Promise<SavedPersonContact> =>
  createRepository(fetch, { ...CONTACT_DB_HEADERS, "If-Match": etag })
    .post<{ id: string; person: Person }>(`${contactDbUrl}`, contact)
    .then(({ id, person }) =>
      mapToSavedPersonContact(
        { id, type: "contact" },
        contact.givenName,
        contact.name,
        person,
        first(contact.emails)?.value
      )
    );

export const createPerson = (fetch: FetchFn, person: PersonFormModel): Promise<SavedPersonContact> =>
  createRepository(fetch, CONTACT_DB_HEADERS)
    .post<{ id: string }>("/1.0/sg/persons", person)
    .then(({ id }) => mapToSavedPersonContact({ id, type: "person" }, person.givenName, person.name));

export const editContactApi = (
  fetch: FetchFn,
  id: string,
  contact: ContactCreatorFormModelDto,
  etag?: string
): Promise<SavedPersonContact> =>
  createRepository(fetch, { ...CONTACT_DB_HEADERS, "If-Match": etag })
    .put<{ id: string }>(`${contactDbUrl}/${id}`, contact)
    .then(({ id }) => mapToSavedPersonContact({ id, type: "contact" }, contact.givenName, contact.name));

export const reactivateContact = (fetch: FetchFn, contactId: string, etag?: string): Promise<ContactDbDto> =>
  createRepository(fetch, { ...CONTACT_DB_HEADERS, "If-Match": etag }).post<ContactDbDto>(
    `${contactDbUrl}/${contactId}/reactivate`
  );

export const getContactSuggestions = (fetch: FetchFn, email: string): Promise<ContactSuggestionDTO> =>
  createRepository(fetch, CONTACT_DB_HEADERS).get<ContactSuggestionDTO>(`${contactDbUrl}/suggestions`, { email });

export const getContactsByPersonId = async (
  fetch: FetchFn,
  personId: string,
  onlyActive = true,
  fields = DEFAULT_CONTACT_DB_PROPERTIES
): Promise<ContactDbDto[]> => {
  return createRepository(fetch, CONTACT_DB_HEADERS)
    .get<{ contacts: ContactDbDto[] }>(contactDbUrl, stringifyLists({ fields, personId, onlyActive }))
    .then(res => res?.contacts);
};

export const deleteContactById = (fetch: FetchFn, contactId: string): Promise<boolean> =>
  createRepository(fetch, CONTACT_DB_HEADERS)
    .delete(`${contactDbUrl}/${contactId}`)
    .then(() => true);

export const deleteContactsByIds = (fetch: FetchFn, contactIds: string[]): Promise<boolean[]> => {
  if (isEmpty(contactIds)) {
    return Promise.resolve([]);
  }
  return Promise.all<boolean>(map(contactIds, id => deleteContactById(fetch, id)));
};

export const getPositionsByPersonId = (
  fetch: FetchFn,
  id: string,
  fields = DEFAULT_CONTACT_DB_PROPERTIES
): Promise<ContactDbDto[]> => {
  return createRepository(fetch, CONTACT_DB_HEADERS)
    .get<{ contacts: ContactDbDto[] }>(`${personUrl}/${id}/positions`, stringifyLists({ fields }))
    .then(res => res?.contacts);
};

export const editPostionByContactId = (
  fetch: FetchFn,
  id: string,
  contact: ChangePositionDto,
  etag?: string
): Promise<SavedPersonContact> =>
  createRepository(fetch, { ...CONTACT_DB_HEADERS, "If-Match": etag })
    .post<{ id: string }>(`${contactDbUrl}/${id}/do-change-position`, contact)
    .then(({ id }) => mapToSavedPersonContact({ id, type: "contact" }));

export const endPostionByContactId = (fetch: FetchFn, contactId: string): Promise<boolean> =>
  createRepository(fetch, CONTACT_DB_HEADERS)
    .post(`${contactDbUrl}/${contactId}/do-close-position`)
    .then(() => true);

export const endPostionByContactIds = (fetch: FetchFn, contactIds: string[]): Promise<boolean[]> => {
  if (isEmpty(contactIds)) {
    return Promise.resolve([]);
  }
  return Promise.all<boolean>(map(contactIds, id => endPostionByContactId(fetch, id)));
};

export const getContactIndicators = (
  repository: ApiRepository,
  contactIds: string[],
  requestConfig?: ApiRequestConfig
): Promise<ContactQualityView[]> => {
  const fetchByIds = (ids: string[]): Promise<ContactQualityView[]> =>
    repository
      .get<{ contactIndicators: ContactQualityDTO[] }>(
        `${contactDbUrl}/quality-indicators`,
        {
          ids: ids?.join(","),
          fields: "dqowners,quality",
        },
        requestConfig as ApiRequestConfig
      )
      .then(result => result.data.contactIndicators?.map(indicators => mapFromQualityDb(indicators)));

  return chunkFetchByIds<ContactQualityView>(contactIds, fetchByIds);
};

export const requestUserPermissions = (fetch: FetchFn, userId: string | undefined): Promise<string[]> =>
  createRepository(fetch, CONTACT_DB_HEADERS)
    .get<UserPermissions>(`${privateUserUrl}/${userId}`)
    .then(resp => resp.permissions);
