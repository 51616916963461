import { Team, TeamDTO } from "@ic-anywhere/ic-dal";
import { FetchFn } from "@sg-widgets/platform-api";
import { createRepository } from "../api";
import { CONTACT_DB_HEADERS } from "../api.constants";
import { TeamMember, TeamPermissionsDTO, TeamSettingsDTO } from "./teams.typings";
import { mapToTeams } from "./teams.mappers";

const urlTeamsContactDb = "/1.0/sg/teams";
const privateTeamsUrl = "/private/0.0/teams";
const urlOrganizationTeams = "/2.0/organizationTeams";

export const getTeamById = (fetch: FetchFn, teamId: string): Promise<Team> =>
  createRepository(fetch, CONTACT_DB_HEADERS).get(`${urlTeamsContactDb}/${teamId}`);

export const getPermissionsByTeamId = (fetch: FetchFn, teamId: string): Promise<TeamPermissionsDTO> =>
  createRepository(fetch, CONTACT_DB_HEADERS).get(`${privateTeamsUrl}/${teamId}/permissions`);

export const getTeamMembersById = (fetch: FetchFn, teamId: string): Promise<TeamMember[]> =>
  createRepository(fetch, CONTACT_DB_HEADERS)
    .get<{ contacts: TeamMember[] }>(`${urlTeamsContactDb}/${teamId}/contacts`, {
      fields: "organizationteam,internalHrTeam",
    })
    .then(r => r.contacts);

export const requestAllOrganizationTeams = (fetch: FetchFn): Promise<Team[]> => {
  let teams: TeamDTO[] = [];
  let page = 0;
  const request = (url: string, params?: Record<string, any> | undefined): Promise<Team[]> => 
    createRepository(fetch)
      .get<{ organizationTeams: TeamDTO[]; nextPageUri: string }>(url, params)
      .then(async result => {
        teams = teams.concat(result.organizationTeams);
        if (!!result.nextPageUri) {
          page = page + 1;
          await request(url, {
            page,
          });
        }
        return mapToTeams(teams);
      });
  return request(`${urlOrganizationTeams}`, {
    page
  });
}


export const requestAllTeams = (fetch: FetchFn): Promise<Team[]> => {
  let teams: Team[] = [];
  let page = 0;
  const request = (url: string, params?: Record<string, any> | undefined): Promise<Team[]> => 
    createRepository(fetch, CONTACT_DB_HEADERS)
      .get<{ teams: Team[]; nextPageUri: string }>(url, params)
      .then(async result => {
        teams = teams.concat(result.teams);
        if (result.nextPageUri) {
          page = page + 1;
          await request(url, {
            page,
          });
        }
        return teams;
      });
  return request(`${urlTeamsContactDb}`, {
    page
  });
}

export const getTeamSettings = (fetch: FetchFn, teamId: string | undefined): Promise<TeamSettingsDTO> =>
createRepository(fetch, CONTACT_DB_HEADERS).get<TeamSettingsDTO>(`${privateTeamsUrl}/${teamId}/settings`);
