import * as React from "react";
import { useField } from "formik";
import parsePhoneNumberFromString, {
  CountryCode,
  formatIncompletePhoneNumber,
  getExampleNumber,
  parseIncompletePhoneNumber,
  isValidNumber,
} from "libphonenumber-js";
import examples from "libphonenumber-js/examples.mobile.json";

import { isEmpty } from "lodash-es";
import { InvalidFeedback } from "../InvalidFeedback/InvalidFeedback";
import { Phone } from "../../typings";

type Props = {
  fieldId: string;
};

const PhoneField: React.FC<Props> = ({ fieldId }) => {
  const [{ value: phoneValue }] = useField<Phone>(fieldId);
  const [, { error: errorOnNumber }, { setValue }] = useField<string | undefined>(`${fieldId}.number`);

  const { number, iso2: countryIso2 } = phoneValue;
  const errorMessage = !isEmpty(errorOnNumber) ? errorOnNumber : "";

  const displayedPhone = React.useMemo(() => {
    if (isEmpty(number)) {
      return "";
    }
    if (isValidNumber(number, countryIso2 as CountryCode)) {
      setValue(parsePhoneNumberFromString(number, countryIso2 as CountryCode)?.formatInternational());
      return parsePhoneNumberFromString(number, countryIso2 as CountryCode)?.formatInternational();
    }
    return formatIncompletePhoneNumber(number, countryIso2 as CountryCode);
  }, [number, countryIso2]);

  const handleChangePhone = (event: any) => {
    const entered = event.target.value;

    if (isEmpty(entered)) {
      setValue("");
    } else {
      const numberToSet =
        parsePhoneNumberFromString(entered, countryIso2 as CountryCode)?.formatInternational() ||
        parseIncompletePhoneNumber(entered).toString();

      setValue(numberToSet);
    }
  };

  const placeholder = isEmpty(countryIso2)
    ? "First, select a country"
    : isEmpty(displayedPhone)
    ? `Ex: ${getExampleNumber(countryIso2 as CountryCode, examples)?.formatInternational()}`
    : "";

  return (
    <div className="form-group">
      <input
        type="tel"
        value={displayedPhone}
        className="form-control form-control-md"
        onChange={handleChangePhone}
        placeholder={placeholder}
      />
      <InvalidFeedback errorMessage={errorMessage} />
    </div>
  );
};

export default PhoneField;
