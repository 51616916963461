import { Select } from "@sgbs-ui/core";
import * as React from "react";
import { useGenerateId } from "../../../common/hooks/useGenerateId";
import { FetchFn } from "@sg-widgets/react-core";
import { useBusinessFunction } from "../useBusinessFunction";
import FormikFormGroup from "../../../common/components/Form/FormikFormGroup";
import { useIntl } from "react-intl";
import { renderBf } from "../../../common/components/renderBf";
import { BusinessFunctionFilter } from "../../../common/typings/mandate.typing";

interface Props {
  fetch: FetchFn;
  roleItemId?: string;
  canRequestAccess?: boolean;
  onRequestAccess?: (bf: BusinessFunctionFilter) => void;
}

const BusinessFunctionField: React.FC<Props> = ({ fetch, roleItemId, canRequestAccess, onRequestAccess }: Props) => {
  const bfId = useGenerateId();
  const roleId = useGenerateId();
  const [
    functions,
    roles,
    { value: bf, error: bfError, touched: bfTouched, onChange: onBfChange },
    { value: role, error: roleError, touched: roleTouched, onChange: onRoleChange },
    shouldDisplay,
  ] = useBusinessFunction(fetch, roleItemId, canRequestAccess);

  const { formatMessage } = useIntl();

  if (!shouldDisplay) {
    return null;
  }

  return (
    <div className="flex-column">
      <FormikFormGroup
        fieldName={"businessFunction"}
        label={formatMessage({
          id: "global.function",
          defaultMessage: "Function",
        })}
      >
        <Select.SingleSelect
          id={bfId}
          items={functions}
          idField="id"
          labelField="name"
          iconName="search"
          placeholder={formatMessage({
            id: "mandates.filters.function.placeholder",
            defaultMessage: "Select a function",
          })}
          size="lg"
          selectedItem={bf}
          onChange={onBfChange}
          inError={bfTouched && !!bfError}
          keepOrder={false}
          noResultMessage={"No results found. Try another function."}
        >
          {(bf, props) => renderBf(canRequestAccess, onRequestAccess)(bf, props)}
        </Select.SingleSelect>
      </FormikFormGroup>
      <FormikFormGroup
        fieldName={"businessFunctionRole"}
        label={formatMessage({
          id: "global.role",
          defaultMessage: "Role",
        })}
      >
        <Select.SingleSelect
          id={roleId}
          items={roles}
          idField="id"
          labelField="name"
          iconName="search"
          placeholder={formatMessage({
            id: "mandates.filters.role.placeholder",
            defaultMessage: "Select a role",
          })}
          size="lg"
          selectedItem={role}
          onChange={onRoleChange}
          inError={roleTouched && !!roleError}
          keepOrder={false}
          showClearButton={roles.length > 1}
          noResultMessage={"No results found. Try another role."}
        />
      </FormikFormGroup>
    </div>
  );
};

export default BusinessFunctionField;
