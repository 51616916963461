import { FetchFn } from "@sg-widgets/platform-api";
import * as React from "react";
import { getCountriesApi } from "../../api/countries/countries.api";
import { mapToCountries } from "./AddPhonesForm/countries.mapper";
import { Country } from "../../common/typings";

export const useFetchCountries = (
  fetch: FetchFn
): { isLoading: boolean; errorMessage: string | undefined; countries: Country[] } => {
  const [countries, setCountries] = React.useState<Country[]>([]);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    setIsLoading(true);
    getCountriesApi(fetch)
      .then(countries => {
        setCountries(mapToCountries(countries));
        setErrorMessage(undefined);
      })
      .catch(() => {
        setErrorMessage("Failed to fetch countries");
      })
      .finally(() => setIsLoading(false));
  }, [fetch]);

  return { isLoading, errorMessage, countries };
};
