import React from "react";
import { MandateFormModel, SavedMandate } from "../../common/typings/mandate.typing";
import { FetchFn } from "@sg-widgets/react-core";
import { saveMandate, addDocumentToMandate } from "../../api/mandate/mandate.api";
import { BankAccountDTO, MandateCreateError } from "../../api/mandate/mandate.typings";
import { ContactId } from "../../common/typings/contacts.typings";
import { mapTobankAccountDTO, mapToMandateCreateError } from "../../api/mandate/mandate.mapper";
import { isEmpty } from "lodash-es";

export const useSaveMandate = (
  fetch: FetchFn,
  onSaved?: (saved: SavedMandate) => void,
  onError?: (errors: MandateCreateError[]) => void
): [(form: MandateFormModel) => void, boolean] => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const errors: MandateCreateError[] = [];

  const onSubmit = async (form: MandateFormModel): Promise<void> => {
    setIsLoading(true);

    const createMandate = async (contact: ContactId, bankAccount?: BankAccountDTO) => {
      await saveMandate(fetch, form, contact, bankAccount)
        .then(response => {
          if (form.document) {
            addDocumentToMandate(fetch, {
              mandateId: response.id,
              type: "indemnityLetter",
              file: form.document,
            }).catch(error => {
              errors.push(mapToMandateCreateError(error, form, contact, bankAccount));
            });
          }
          return response;
        })

        .then((saved: SavedMandate) => {
          if (isEmpty(errors)) {
            onSaved?.(saved);
          }
        })
        .catch(error => {
          errors.push(mapToMandateCreateError(error, form, contact, bankAccount));
        });
    };

    await Promise.all(
      form.contacts.map(async contact => {
        form.bankAccounts?.accountNumbers?.length
          ? await Promise.all(
              form.bankAccounts.accountNumbers.map(async bankAccount =>
                createMandate(contact, mapTobankAccountDTO(form.bankAccounts?.all, bankAccount))
              )
            )
          : await createMandate(contact, form.bankAccounts ? mapTobankAccountDTO(form.bankAccounts?.all) : undefined);
      })
    ).finally(() => {
      setIsLoading(false);
      if (!!errors?.length) {
        onError?.(errors);
      }
    });
  };

  return [onSubmit, isLoading];
};
