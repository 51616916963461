import { Select } from "@sgbs-ui/core";
import * as React from "react";
import { Country } from "../../typings";
import { useField } from "formik";
import { find, sortBy } from "lodash-es";
import { isContainSubString } from "../../../utils/strings/stringUtils";
import { useIntl } from "react-intl";

type Props = {
  fieldId: string;
  countries: Country[];
  errorMessage?: string | undefined;
};

const CountryField: React.FC<Props> = ({ countries, fieldId, errorMessage }: Props) => {
  const [{ value: iso2Value }, { touched, error }, { setValue }] = useField<string | null>(fieldId);
  const [term, setTerm] = React.useState<string>("");
  const { formatMessage } = useIntl();

  const handleChange = (selected: Country | null) => {
    setValue(selected?.iso2 || "");
  };

  const quickSearchPredicate = (country) => !!country?.iso3 && !!country?.areaCode && (isContainSubString(country.iso3, term) || isContainSubString(country.name, term) || isContainSubString(country.areaCode, term) || isContainSubString(`00${country.areaCode}`, term) || isContainSubString(`+${country.areaCode}`, term))
  const sortedCountries = React.useMemo(() => sortBy(countries, c => c.iso2), [countries]);

  return (
    <Select.SingleSelect<Country>
      id={fieldId}
      items={sortedCountries}
      idField="iso2"
      labelField="name"
      placeholder={`${formatMessage({
        id: "global.search",
        defaultMessage: "Search",
      })}...`}
      size="md"
      selectedItem={find(countries, c => c.iso2 === iso2Value) || null}
      onChange={handleChange}
      inError={touched && !!error}
      keepOrder={false}
      showClearButton={false}
      errorMessage={errorMessage}
      onTermChange={setTerm}
      noResultMessage={"No results found. Try another country."}
      quickSearchPredicate={quickSearchPredicate}
    >
      {({ iso2, iso3, areaCode }) => (
        <>
          {iso2 && <div className={`flag-icon flag-icon-${iso2.toLowerCase()} mr-2`} />}
          {`${iso3}`}
          {areaCode && ` (+${areaCode})`}
        </>
      )}
    </Select.SingleSelect>
  );
};

export default CountryField;
