import * as React from "react";
import UploadSquares from "./UploadSquares";

export interface DropZoneProps {
  onFilesAdded: (files: FileList) => void;
  accept?: string;
  size?: "normal" | "small";
}

const DropZone: React.FC<DropZoneProps> = ({ onFilesAdded, accept, size = "normal" }) => {
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);
  const openFileDialog = () => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleOnDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    onFilesAdded(event.dataTransfer.files);
  };
  const handleOnFileAdded = (event: any) => {
    onFilesAdded(event.target.files);
    // force trigger onChange when user chooses the same file
    event.target.value = "";
  };
  const handleOnDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  return (
    <div className="position-relative d-block" onDrop={handleOnDrop} onDragOver={handleOnDragOver}>
      <UploadSquares>
        <div
          className={`upload-inner ${
            size === "normal" ? "py-3" : "py-2"
          } text-center d-flex align-items-center justify-content-center`}
        >
          {size === "normal" && <em className="icon icon-xl text-light mx-5">attach_file</em>}
          <p className="text-secondary m-0 line-height-1">
            Drag or
            <label className={`text-info px-1 mb-0 pointer`} onClick={openFileDialog}>
              select
            </label>
            your file to this area to upload
          </p>
          <input ref={fileInputRef} className="d-none" type="file" onChange={handleOnFileAdded} accept={accept} />
        </div>
      </UploadSquares>
    </div>
  );
};

export default DropZone;
