import * as React from "react";
import { Button, ChildrenRenderProps } from "@sgbs-ui/core";
import { BusinessFunctionFilter } from "../typings/mandate.typing";

export function renderBf(withRequestAccess?: boolean, onRequestAccess?: (bf: BusinessFunctionFilter) => void) {
  // eslint-disable-next-line react/display-name
  return (bf: BusinessFunctionFilter, { withHighlight }: ChildrenRenderProps): React.ReactElement => {
    const handleOnRequestAccess = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      event.preventDefault();
      event.stopPropagation();
      onRequestAccess?.(bf);
    };

    const preventSelection = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
      if (withRequestAccess && !bf.isAssignable) {
        event.preventDefault();
        event.stopPropagation();
      }
    };

    return (
      <div onClick={preventSelection} className={`d-flex align-items-center`}>
        <div style={{ flex: 1, flexGrow: 1 }}>{withHighlight(bf?.friendlyName ?? "")}</div>
        {withRequestAccess && !bf.isAssignable ? (
          <div className="ml-1" style={{ flex: "0 0 108", overflow: "hidden", whiteSpace: "nowrap" }}>
            <Button size="sm" color="warning" text="Request access" onClick={handleOnRequestAccess} />
          </div>
        ) : null}
      </div>
    );
  };
}
