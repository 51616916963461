import { IdName } from "@ic-anywhere/ic-dal";
import { first } from "lodash-es";
import { ContactCreatorFormModel, PepSpoType } from "../../common/typings";

interface LabelValue<T> {
  label: string;
  value: T;
}

export const titleLabelsValues: Array<LabelValue<string>> = [
  { label: "Count", value: "Count" },
  { label: "Diplomate", value: "Diplomate" },
  { label: "Doctor", value: "Doctor" },
  { label: "Engineer", value: "Engineer" },
  { label: "Father", value: "Father" },
  { label: "Frau Dr.", value: "Frau Dr." },
  { label: "Frau Mag.", value: "Frau Mag." },
  { label: "Frau Prof.", value: "Frau Prof." },
  { label: "General", value: "General" },
  { label: "Geometra", value: "Geometra" },
  { label: "Her grace", value: "Her grace" },
  { label: "Herr Dr.", value: "Herr Dr." },
  { label: "Herr Mag.", value: "Herr Mag." },
  { label: "Herr Prof.", value: "Herr Prof." },
  { label: "Lord", value: "Lord" },
  { label: "Mayor", value: "Mayor" },
  { label: "Monsignor", value: "Monsignor" },
  { label: "President", value: "President" },
  { label: "Professor", value: "Professor" },
  { label: "Reverend", value: "Reverend" },
  { label: "Senior", value: "Senior" },
  { label: "Sir", value: "Sir" },
  { label: "The Honorable", value: "The Honorable" },
  { label: "CFA", value: "CFA" },
  { label: "MBA", value: "MBA" },
  { label: "CAIA", value: "CAIA" },
  { label: "FRM", value: "FRM" },
  { label: "CPA", value: "CPA" },
  { label: "PhD", value: "PhD" },
];

export const jobTypeLabelsValues: Array<IdName<string>> = [
  { name: "Advisory", id: "Advisory" },
  { name: "Analyst", id: "Analyst" },
  { name: "Assistant", id: "Assistant" },
  { name: "Chief executive officer", id: "Chief executive officer" },
  { name: "Chief financial officer", id: "Chief financial officer" },
  { name: "Chief investment officer", id: "Chief investment officer" },
  { name: "Chief operation officer", id: "Chief operation officer" },
  { name: "Chief risk officer", id: "Chief risk officer" },
  { name: "Communication & media", id: "Communication & media" },
  { name: "Consulting", id: "Consulting" },
  { name: "HR", id: "HR" },
  { name: "KYC", id: "KYC" },
  { name: "Legal and tax", id: "Legal and tax" },
  { name: "Management", id: "Management" },
  { name: "Operations", id: "Operations" },
  { name: "Political", id: "Political" },
  { name: "Portfolio management", id: "Portfolio management" },
  { name: "Sales and trading", id: "Sales and trading" },
  { name: "Senior management", id: "Senior management" },
  { name: "Senior public official", id: "Senior public official" },
  { name: "Stakeholder", id: "Stakeholder" },
  { name: "Treasury & finance", id: "Treasury & finance" },
  { name: "Journalist", id: "Journalist" },
  { name: "Other", id: "-" },
];

export const pepSpoTypes = [
  { label: "N/A", value: undefined },
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const defaultEmailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export type PhoneType = "Business" | "Mobile" | "Reception" | "Business Fax" | "Pager" | "Assistant";

export const phoneTypes = [
  { label: "Business" },
  { label: "Mobile" },
  { label: "Reception" },
  { label: "Business Fax" },
  { label: "Pager" },
  { label: "Assistant" },
];

export const MaxElementOfOptionalInput = {
  PHONE: 3,
  EMAIL: 2,
  ADDRESS: 2,
};

export const initialValues: ContactCreatorFormModel = {
  person: undefined,
  employeeOfAccountId: "",
  civility: "",
  title: "",
  firstName: "",
  lastName: "",
  groupName: "",
  mainEmail: { value: "" },
  jobTitle: "",
  jobType: "",
  pepSpoType: first(pepSpoTypes)?.label as PepSpoType,
  emails: [],
  addresses: [],
  phones: [],
  visibility: "Public",
  owners: { contactIds: [] },
};
