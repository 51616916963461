import * as React from "react";
import FormikFormGroup from "../../../common/components/Form/FormikFormGroup";
import { IcAccountLevelType, ThirdId } from "../../../api/maestro/maestro.typings";
import { useField } from "formik";
import { useFetchDelegationById } from "../useDelegation";
import { FetchFn } from "@sg-widgets/react-core";
import SingleAccountPicker from "../../../ic-account-single-picker/AccountSingleSelectPicker";
import { AccountWidget } from "../../../common/typings/accounts.typings";
import { useFetchClientByThirdId } from "../../useFetchClient";
import { useIntl } from "react-intl";

interface Props {
  thirdId?: ThirdId | null;
  delegationId?: string;
  onChange: (thirdId?: ThirdId) => void;
  fetch: FetchFn;
}

const ThirdPartyField: React.FC<Props> = ({ thirdId, delegationId, onChange, fetch }: Props) => {
  const [, { error, touched }, { setValue, setTouched }] = useField<ThirdId | null>("thirdParty");
  const [selectedThirdId, setSelectedThirdId] = React.useState<ThirdId | null | undefined>(thirdId);
  const { delegation } = useFetchDelegationById(fetch, delegationId);
  const { formatMessage } = useIntl();
  const { account, errorMessage } = useFetchClientByThirdId(fetch, selectedThirdId?.bdrId, selectedThirdId?.level);

  React.useEffect(() => {
    if (selectedThirdId !== thirdId) setSelectedThirdId(thirdId);
  }, [thirdId]);

  React.useEffect(() => {
    if (delegation) {
      setSelectedThirdId({
        bdrId: delegation.account?.bdrLegalId,
        level: delegation.account.level as IcAccountLevelType,
      });
    } else setSelectedThirdId(null);
  }, [delegation]);

  const handleOnChange = (from: AccountWidget | null): void => {
    const value =
      from?.bdrLegalId && from?.level
        ? { bdrId: from.bdrLegalId, level: from.level as IcAccountLevelType }
        : null;
    setValue(value);
    setTouched(true);
    onChange(value || undefined);
  };

  return (
    <FormikFormGroup
      label={formatMessage({
        id: "global.mandatedThirdParty",
        defaultMessage: "Mandated third party",
      })}
      fieldName="thirdParty"
    >
      <SingleAccountPicker
        selectedId={account?.id}
        onChange={handleOnChange}
        placeholder={formatMessage({
          id: "global.picker.account.placeholder",
          defaultMessage: "Search a name, BDR ID or RCT ID",
        })}
        size="lg"
        inError={(touched && !!error) || !!errorMessage}
        emitMode="not-empty"
        createLinkMode="none"
        displayOption="bdr-legal-id"
        withFunds={true}
      />
    </FormikFormGroup>
  );
};

export default ThirdPartyField;
