import * as React from "react";
import { PersonContact } from "../common/typings/contacts.typings";
import { Select } from "@sgbs-ui/core";
import { FetchFn, useSgConnectFetch } from "@sg-widgets/react-core";
import { SGMContactScope } from "../common/sgConnectScopes";
import { fetchIndividualContactByIds, searchIndividualContacts } from "../api/contacts/contacts.api";
import { CommonPickerProps, CreateLinkPickerProps } from "../common/typings";
import { renderIndividualContact } from "../common/components/renderIndividualContact";
import { InvalidFeedback } from "../common/components/InvalidFeedback/InvalidFeedback";
import { useGenerateId } from "../common/hooks/useGenerateId";
import { useMultiSelectPicker } from "../common/hooks/useMultiSelectPicker";
import { useFetchGeneric } from "../common/hooks/useFetchGeneric";
import { useFetchHistory } from "../common/hooks/useHistory";
import { isEmpty } from "lodash-es";
import { useGetContactIndicatorsQuery } from "../common/components/ContactQuality/Queries/ContactQuality.queries";
import { ApiRepository, ApiRequestConfig, ThirdId } from "@ic-anywhere/ic-dal";

const CONTACTS_SEARCH_FIELDS = ["name", "mainEmail", "fullName", "givenName"];
const PICKER_NAME = "ic-person-contact-multi-picker";

interface Props extends CommonPickerProps<PersonContact[]>, CreateLinkPickerProps {
  selectedIds?: string[];
  readonlyIds?: string[];
  functionId?: string;
  roleId?: string;
  dropdownZIndex?: number;
  ignoreClickOutside?: boolean;
  showMandateCounter?: boolean;
  withQualityIndicators?: boolean;
  useHistory?: boolean;
  contactsIds?: string[];
  mandateThirdId?: ThirdId;
  onClickOnMandates?: (item: PersonContact) => void;
}

const PersonContactMultiPicker: React.FC<Props> = ({
  selectedIds,
  readonlyIds,
  maxResultCount,
  onChange,
  onClickOnMandates,
  onReady,
  emitMode,
  dropdownZIndex,
  ignoreClickOutside,
  functionId,
  roleId,
  showMandateCounter,
  withQualityIndicators = false,
  useHistory = false,
  contactsIds,
  mandateThirdId,
  ...props
}: Props) => {
  const pickerId = useGenerateId(props.id);
  const fetch = useSgConnectFetch(SGMContactScope).fetch as FetchFn;

  const fetchContacts = React.useCallback(
    (term: string, repo: ApiRepository, signal: AbortSignal): Promise<PersonContact[]> => {
      return searchIndividualContacts(repo, term, maxResultCount, functionId, roleId, mandateThirdId, { signal } as ApiRequestConfig);
    },
    [fetch, maxResultCount, functionId, roleId, mandateThirdId]
  );

  const fetchContactsByIds = React.useCallback(
    (contactsIdsFromHistory?: string[]): Promise<PersonContact[]> => {
      return fetchIndividualContactByIds(fetch, contactsIds ?? contactsIdsFromHistory ?? [], functionId, roleId, mandateThirdId);
    },
    [fetch, contactsIds, functionId, roleId, mandateThirdId]
  );

  const [selectedItem, readOnlyItems, onSelect] = useMultiSelectPicker(
    fetchContactsByIds,
    emitMode,
    onChange,
    onReady,
    selectedIds,
    readonlyIds
  );

  const [contacts, isLoading, hasError, errorMessage, onTermChange, searchTerm] = useFetchGeneric<PersonContact>(
    fetchContacts,
    fetchContactsByIds,
    CONTACTS_SEARCH_FIELDS,
    contactsIds
  );

  const [history, setHistory, removeItemFromHistory, clearHistory] = useFetchHistory(
    fetchContactsByIds,
    PICKER_NAME,
    useHistory
  );
  const { data: contactQuality } = useGetContactIndicatorsQuery(
    contacts?.map(c => c.id),
    withQualityIndicators
  );

  return (
    <>
      <Select.AsyncSelect<PersonContact>
        id={pickerId}
        items={isEmpty(contacts) && isEmpty(searchTerm) ? history : contacts}
        selectedItems={selectedItem}
        readOnlyItems={readOnlyItems}
        idField="id"
        labelField="fullName"
        iconName="person"
        onTermChange={onTermChange}
        onChange={items => {
          setHistory(items?.map(item => item.id));
          onSelect(items);
        }}
        placeholder={props.placeholder}
        inError={hasError || props.inError || !!errorMessage}
        errorMessage={errorMessage}
        size={props.size as never}
        isLoading={isLoading}
        keepOrder={false}
        onCreatClick={props.onCreateLinkClicked}
        showCreateAction={props.createLinkMode !== "none"}
        isOutline={props.outline}
        createActionText={props.createActionText}
        disabled={props.disabled}
        dropdownZIndex={dropdownZIndex}
        ignoreClickOutside={ignoreClickOutside}
        noResultMessage={"No results found. Try another person or contact."}
        isSuggestionField="isSuggestion"
        onClearSuggestions={() => clearHistory()}
        onRemoveSuggestion={(item: PersonContact) => removeItemFromHistory([item.id])}
        eraseSearchTermOnClick={true}
      >
        {(item, props) =>
          renderIndividualContact(
            onClickOnMandates,
            showMandateCounter,
            contactQuality?.find(cq => cq.contact.id === item.id)
          )(item, props)
        }
      </Select.AsyncSelect>
      {props.inError && <InvalidFeedback errorMessage={props.errorMessage} />}
    </>
  );
};

export default PersonContactMultiPicker;
