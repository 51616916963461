import { FetchFn } from "@sg-widgets/platform-api/src/index";
import { CountryDto } from "./countries.typings";
import { createRepository } from "../api";

const url = "private/0.0/referentials/countries";

export const getCountriesApi = (fetch: FetchFn): Promise<CountryDto[]> => {
  return createRepository(fetch)
    .get<{ countries: CountryDto[] }>(url, { pageSize: 500 })
    .then(res => res?.countries ?? []);
};
