import { FetchFn } from "@sg-widgets/platform-api/src";
import { createRepository } from "../api";
import { CONTACT_DB_HEADERS } from "../api.constants";
import { IdName } from "@ic-anywhere/ic-dal";

const referentialsUrl = "/1.0/referentials";


export const requestJobTypesReferential = (fetch: FetchFn, language = "en"): Promise<IdName<string>[]> =>
  createRepository(fetch, {...CONTACT_DB_HEADERS, "accept-language": language})
    .get<{ jobTypes: IdName<string>[] }>(`${referentialsUrl}/contacts/jobtypes`)
    .then(res => res.jobTypes);

export const requestUboTypesReferential = (fetch: FetchFn, language = "en"): Promise<IdName<string>[]> =>
  createRepository(fetch, {...CONTACT_DB_HEADERS, "accept-language": language})
    .get<{ uboTypes: IdName<string>[] }>(`${referentialsUrl}/mandates/ubotypes`)
    .then(res => res.uboTypes);
