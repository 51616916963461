import * as React from "react";
import { EmitOnChangeMode } from "../typings";
import { isEmpty, isEqual } from "lodash-es";

type UsePickerSelectedResult<R> = [R[], R[], (item: R[]) => void];

export const useMultiSelectPicker = <R, S = string>(
  fetchBy: (input: S[]) => Promise<R[]>,
  emitMode?: EmitOnChangeMode,
  onChange?: (input: R[]) => void,
  onReady?: () => void,
  selected?: S[],
  readonly?: S[],
  idField: string = "id",
  customShouldNotFetch?: (selectedIds: S[] | undefined, items: R[]) => boolean
): UsePickerSelectedResult<R> => {
  const [selectedItems, setSelectedItems] = useItems<R, S>(
    selected,
    emitMode,
    fetchBy,
    onChange,
    idField,
    customShouldNotFetch
  );
  const [readOnlyItems] = useItems<R, S>(readonly, emitMode, fetchBy, onChange, idField);

  React.useEffect(() => {
    onReady?.();
  }, [onReady]);

  // manual selection
  const onSelect = (items: R[]): void => {
    setSelectedItems(items);
    onChange?.(items);
  };

  return [selectedItems, readOnlyItems, onSelect];
};

function useItems<T, S = string>(
  ids: S[] | undefined,
  emitMode,
  fetchBy,
  onChange,
  idField: string = "id",
  customShouldNotFetch?: (selectedIds: S[] | undefined, items: T[]) => boolean
): [T[], (items: T[]) => void, (items: T[]) => void] {
  const [items, setItems] = React.useState<T[]>([]);

  const shouldNotFetch = React.useMemo(() => {
    return customShouldNotFetch
      ? customShouldNotFetch(ids, items)
      : isEqual(
          ids,
          items.map(item => item[idField])
        );
  }, [items, idField, ids, customShouldNotFetch]);

  const handleChange = (items: T[]) => {
    if (emitMode === "always" || (emitMode === "not-empty" && !isEmpty(items))) {
      onChange?.(items);
    }
  };

  React.useEffect(() => {
    // don't fetch selected ids if already fetched
    if (shouldNotFetch) {
      return;
    }

    // do nothing if undefined or null array
    if (ids === null || ids === undefined) {
      return;
    }

    // empty array
    if (ids.length === 0) {
      setItems([]);
      handleChange([]);
      return;
    }

    fetchBy(ids!).then(response => {
      setItems(response);
      handleChange(response);
    });
  }, [fetchBy, ids, emitMode]);

  return [items, setItems, handleChange];
}
